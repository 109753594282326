<template>
    <div class="editPropertyWrapper">
        <b-card>
            <b-spinner style="width: 50px; height: 50px" class="spinner"  v-if="showSpinner"/>
            <!-- <div class="row">
                <h3>Edit Property</h3>
            </div> -->
            <div class="row">
                <b-col lg="9" md="12" sm="12">
                <b-col md="12" v-if="listingType == 'mental_or_detox'">
                    <SaleByOwnerComponent  />
                </b-col>
                <b-col md="12" v-if="listingType == 'free_or_public'">
                    <AssignYourContractComponent />
                </b-col>
                <b-col md="12" v-if="listingType == 'half_way_or_shared' || listingType == 'mental_or_detox_facility' || listingType == 'free_or_public_shelter'">
                    <ListYourMortgageComponent />
                </b-col>
            </b-col>
            <b-col lg="3" md="12" sm="12">

            </b-col>
            </div>
        </b-card>
    </div>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BSpinner,
  BCard,
  BFormDatepicker,
  BFormFile,
  BAvatar,
  BButton,
  BTable,
  BPagination,
  BFormSelect,
  BBadge,
  BDropdown,
  BDropdownItem,
  BInputGroup,
  BInputGroupAppend
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { dbCollections } from "@/utils/firebaseCollections";
import { required } from "@validations";
import { mapGetters, mapActions } from "vuex";
import {getCommaSeperatedNumber } from "@/utils/commonMethods";
import SaleByOwnerComponent from "@/views/admin/listing-management/editComponent/MyListingsviewForm.vue"
import AssignYourContractComponent from "@/views/admin/listing-management/editComponent/AssignYourContractListing.vue"
import ListYourMortgageComponent from "@/views/admin/listing-management/editComponent/ListYourMortgageListing.vue"

export default{
    name: "listing-management-edit",
      components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
    SaleByOwnerComponent,
    AssignYourContractComponent,
    ListYourMortgageComponent
  },
  directives: {
    Ripple,
  },
    data(){
        return{
            showSpinner : true,
            listingType:''
        }
    },
    created(){
        var self = this;
        self.idSpecificPropertyData(self.$route.params.id).then(() => {
            if(self.getIdSpecificPropertyArray && self.getIdSpecificPropertyArray.length){
                self.showSpinner = false;
                self.listingType = self.getIdSpecificPropertyArray[0].listingType;
            }
        })
    },
    methods:{
        ...mapActions({
            idSpecificPropertyData : "property/idSpecificPropertyData"
        })
    },
    computed: {
        ...mapGetters({
            getIdSpecificPropertyArray : "property/getIdSpecificProperty"
        })
    }
}
</script>
<style>
.spinner {
  z-index: 999;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
</style>