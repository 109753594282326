import firebase from "firebase";

export const uploadImageAsPromise = async (imageFile, storageLocationURL, fileName) => {
    return new Promise((resolve, reject) => {
        const storageRef = firebase.storage().ref();
        const uploadTask = storageRef.child(storageLocationURL + fileName).put(imageFile);

        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log(progress);
            },
            (error) => {
                console.log(error);
                reject(error);
            },
            async () => {
                const imgURL = await uploadTask.snapshot.ref.getDownloadURL();
                resolve(imgURL);
            }
        );
    });
}

export const removeImage = (folderName, imageName, callback) => {
    var storageRef = firebase.storage().ref();
    var desertRef = storageRef.child(folderName + imageName);
    desertRef.delete().then(()=> {
        callback(true);
      })
      .catch(function(error) {
        callback(error);
      });
  }