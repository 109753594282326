<template>
    <div class="container view-property-section">
        <b-spinner v-if="isSpinner" class="spinner"></b-spinner>
        <div class="row" v-if="!isSpinner">
            <div class="col-md-12 p-0">
                <!-- <div class="propertyAddressBtnWrapper">
                    <div class="overview-section propertyAddress">
                        <span class="dashBoardTitle"><img class="rightArrow"  src="../../../../assets/images/svg/arrowLeft.svg" @click="goBackFun()" />
                        <span class="dashBoardTitleText" :title="`${formData.leaseAStreetNumber} ${formData.leaseAStreetName} ${formData.leaseAUnitorLot ? `${formData.leaseAUnitorLot},` : ''} ${formData.leaseBCity}, ${formData.leaseBCountry}, ${formData.leaseBZipCode}`">
                            {{formData.leaseAStreetNumber}} {{formData.leaseAStreetName}}, {{formData.leaseAUnitorLot ? `${formData.leaseAUnitorLot},` : ''}} {{formData.leaseBCity}}, {{formData.leaseBState}}, {{formData.leaseBCountry.name ? formData.leaseBCountry.name : formData.leaseBCountry }}, {{formData.leaseBZipCode}}
                        </span>
                        </span>
                    </div>
                </div> -->
                <div class="myListingsropertyDetail">
                    <!-- Property Detail End -->
                    <validation-observer ref="observer">
                        <h5 class="propertyDetailHeading">Property Details</h5>
                        <b-form class="loginForm">
                            <div class="row">
                                <div class="col-md-3">
                                    <validation-provider
                                        :rules="{ required: true}"
                                    >
                                        <b-form-group
                                            class="userNameFormGroup"
                                            label-for="streetnum"
                                            slot-scope="{ failedRules }"
                                        >
                                        <template #label>
                                            Street Number <span class="errorAasterisk">*</span>
                                        </template>
                                            <b-form-input
                                            class="InputFormClass"
                                            v-model="formData.leaseAStreetNumber"
                                            type="text"
                                            id="streetnum"
                                            placeholder="Enter street number"
                                            maxLength="10"
                                            @keyup="latAndLong()"
                                            ></b-form-input>
                                            <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                        </b-form-group>
                                    </validation-provider>
                                </div>
                                <div class="col-md-6">
                                    <validation-provider
                                        :rules="{ required: true}"
                                    >
                                        <b-form-group
                                            class="userNameFormGroup"
                                            label="Street Name"
                                            label-for="streetname"
                                            slot-scope="{ failedRules }"
                                        >
                                        <template #label>
                                            Street Name <span class="errorAasterisk">*</span>
                                        </template>
                                            <b-form-input
                                                class="InputFormClass"
                                                v-model="formData.leaseAStreetName"
                                                type="text"
                                                id="streetname"
                                                placeholder="Enter street name"
                                                maxLength="50"
                                                @keyup="latAndLong()"
                                            ></b-form-input>
                                            <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                        </b-form-group>
                                    </validation-provider>
                                </div>
                                <div class="col-md-3 unitCheckBox">
                                    <b-form-group
                                        class="userNameFormGroup"
                                        id="inputwithCheckBoxFormGroup"
                                        label-for="annualhOA"
                                    >
                                        <div class="dropDownCompnayState d-flex">
                                            <label class="userNameFormGroup annualAcnt">Unit or Lot <span class="errorAasterisk">*</span></label>
                                            <div class="checBox notapplicableCheckBox">  
                                                <div class="form-group-chekbox">
                                                    <input type="checkbox" id="notapplicabale" v-model="formData.leaseCIsleaseAUnitorLot" />
                                                    <label for="notapplicabale" class="checkBoxLabel d-flex align-items-center">N/A</label>
                                                </div>
                                            </div>
                                        </div>
                                        <validation-provider
                                                    :rules="formData.leaseCIsleaseAUnitorLot == false ? {required:true} : ''"
                                                >  
                                            <div slot-scope="{ failedRules }" class="form-group-inputRequired">
                                                <div class="form-group-inputRequired">
                                                    <b-form-input
                                                        class="InputFormClass"
                                                        v-model="formData.leaseAUnitorLot"
                                                        type="text"
                                                        id="streetnum"
                                                        placeholder="Enter Unit or Lot"
                                                        maxLength="100"
                                                        @keyup="latAndLong()"
                                                    ></b-form-input>
                                                </div>
                                                <small class="text-danger" v-if="failedRules.required">The field is required.</small>
                                            </div>
                                        </validation-provider>
                                    </b-form-group>
                                </div>
                                <!-- <div class="col-md-3">
                                    <b-form-group
                                        class="userNameFormGroup"
                                        label-for="unitlot"
                                    >
                                    <template #label>
                                        Unit or Lot
                                    </template>
                                        <b-form-input
                                        class="InputFormClass"
                                        v-model="formData.leaseAUnitorLot"
                                        type="text"
                                        id="unitlot"
                                        placeholder="Enter Unit or Lot"
                                        maxLength="10"
                                        ></b-form-input>
                                    </b-form-group>
                                </div> -->
                            </div>
                            <div class="row countrystate">
                                <div class="col-md-6">
                                    <validation-provider
                                        :rules="{ required: true}"
                                    >
                                        <b-form-group
                                            class="userNameFormGroup"
                                            label-for="input-1"
                                            slot-scope="{ failedRules }"
                                        >
                                            <template #label>
                                                City <span class="errorAasterisk">*</span>
                                            </template>
                                            <b-form-input
                                            class="InputFormClass"
                                            v-model="formData.leaseBCity"
                                            type="text"
                                            maxLength="50"
                                            placeholder="Enter city"
                                            @keyup="latAndLong()"
                                            ></b-form-input>
                                            <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                        </b-form-group>
                                    </validation-provider>
                                </div>
                                <div class="col-md-6 selectWrapper">
                                    <label>State <span class="errorAasterisk">*</span></label>
                                    <div class="customerStyle">
                                        <validation-provider
                                            :rules="{ required: true}"
                                        >
                                            <div slot-scope="{ failedRules }">
                                                <!-- <select class="selectCustomer" v-model="formData.leaseBState">
                                                    <option value="">Select</option>
                                                    <option value="gujarat">gujarat</option>
                                                    <option value="Kolkata">Kolkata</option>
                                                </select> -->
                                                <select
                                                    class="selectCustomer"
                                                    v-model="formData.leaseBState"
                                                    @change="latAndLong()"
                                                >
                                                <option :value='null' disabled>Select</option>
                                                <option
                                                    v-for="(dataVal, index) in stateList"
                                                    :key="index"
                                                    :value="dataVal.name"
                                                >
                                                    {{ dataVal.name }}
                                                </option>
                                                </select>
                                                <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                            </div>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row">
                                
                                <div class="col-md-6">
                                    <validation-provider
                                        :rules="{ required: true}"
                                    >
                                        <b-form-group
                                            class="userNameFormGroup"
                                            label="Zip code"
                                            label-for="input-1"
                                            slot-scope="{ failedRules }"
                                        >
                                        <template #label>
                                            Zip code <span class="errorAasterisk">*</span>
                                        </template>
                                            <b-form-input
                                            class="InputFormClass"
                                            v-model="formData.leaseBZipCode"
                                            type="text"
                                            placeholder="Enter zip code"
                                            maxLength="10"
                                            autocomplete="off"
                                            ></b-form-input>
                                            <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                        </b-form-group>
                                    </validation-provider>
                                </div>
                                 <div class="col-md-6 selectWrapper">
                                    <label>Country <span class="errorAasterisk">*</span></label>
                                        <div class="customerStyle">
                                            <validation-provider
                                                :rules="{ required: true}"
                                            >
                                                <div slot-scope="{ failedRules }">

                                                    <!-- <select class="selectCustomer" v-model="formData.leaseBCountry">
                                                        <option value="">Select</option>
                                                        <option value="india">india</option>
                                                        <option value="us">us</option>
                                                    </select> -->
                                                    <select
                                                        class="selectCustomer"
                                                        v-model="formData.leaseBCountry"
                                                        @change="latAndLong()"
                                                        >
                                                        <option
                                                            v-for="(data, index) in countryList"
                                                            :key="index"
                                                            :value="data.name"
                                                        >
                                                            {{ data.name }}
                                                        </option>
                                                    </select>
                                                    <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                                </div>
                                            </validation-provider>
                                        </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <validation-provider
                                        :rules="{ regex:/^(\+|-)?(?:90(?:(?:\.0{1,14})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,25})?))$/}"
                                    >
                                        <b-form-group
                                            class="userNameFormGroup"
                                            label-for="latitude"
                                            slot-scope="{ failedRules }"
                                        >
                                        <template #label>
                                            Latitude (optional)
                                        </template>
                                            <b-form-input
                                            class="InputFormClass"
                                            v-model="formData.leaseBLatitude"
                                            type="text"
                                            id="latittude"
                                            placeholder="Enter latitude"
                                            maxLength="30"
                                            ></b-form-input>
                                            <div v-if="failedRules.regex">
                                                <!-- <small class="text-danger error" v-if="failedRules.required">The field is required.</small> -->
                                                <small class="text-danger error" v-if="failedRules.regex">Please provide valid value.</small>
                                            </div>
                                            <span style="display: block !important;margin-top:15px !important;" class="accuracy">Latitude and longitude can be indicated to map the property or a nearby location.</span>
                                        </b-form-group>
                                    </validation-provider>
                                </div>
                                <div class="col-md-6">
                                    <validation-provider
                                        :rules="{regex:/^(\+|-)?(?:180(?:(?:\.0{1,14})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,25})?))$/}"
                                    >
                                        <b-form-group
                                            class="userNameFormGroup"
                                            label-for="longitude"
                                            slot-scope="{ failedRules }"
                                        >
                                        <template #label>
                                            Longitude (optional)
                                        </template>
                                            <b-form-input
                                            class="InputFormClass"
                                            v-model="formData.leaseBLongitude"
                                            type="text"
                                            maxLength="30"
                                            id="longitude"
                                            placeholder="Enter longitude"
                                            ></b-form-input>
                                            <div v-if="failedRules.regex">
                                                <!-- <small class="text-danger error" v-if="failedRules.required">The field is required.</small> -->
                                                <small class="text-danger error" v-if="failedRules.regex">Please provide valid value.</small>
                                            </div>
                                            <span style="display: block !important;margin-top:15px !important;text-decoration:underline;" class="Get_long"><a target="_blank" href="https://www.latlong.net/">Click here to get lat/long</a></span>
                                        </b-form-group>
                                    </validation-provider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <validation-provider
                                    :rules="{}"
                                    >
                                <b-form-group
                                slot-scope="{ failedRules }"
                                    class="userNameFormGroup textAreaClassGroup"
                                    label-for="input-1"
                                >
                                <template #label>
                                        Description
                                        <span class="textCount">{{descriptionLength ? descriptionLength : 0}}/1000</span>
                                        </template>
                                    <vue-editor  
                                        v-model="formData.leaseBDescription" 
                                        placeholder="Use this area to highlight your shelter or program, and disclose anything unique related to policies around children, pets, smoking, etc. Be sure to let people know how they can see your location if they’d like to. You can attach a 1-minute video walk-through and photos at the end of this form."
                                        ref="refComment" 
                                        :editorToolbar="customToolbar" 
                                        v-on:text-change="callTextChange"
                                        class="InputFormClass textAreaClass"
                                    >
                                    </vue-editor>
                                <!-- <b-form-textarea
                                    class="InputFormClass textAreaClass"
                                    v-model="formData.leaseBDescription"
                                    type="textarea"
                                    maxLength="1000"
                                    placeholder="Write a quick description of the property and any recent repairs"
                                    ></b-form-textarea> -->
                                    <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                </b-form-group>
                                </validation-provider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                <b-form-group
                                    class="userNameFormGroup textAreaClassGroup"
                                    label-for="input-1"
                                >
                                <template #label>
                                    Intake Instructions
                                </template>
                                <b-form-textarea
                                    class="InputFormClass textAreaClass"
                                    v-model="formData.leaseBShowingInstructions"
                                    type="textarea"
                                    maxLength="1000"
                                    placeholder="A seller may wish to place a lockbox with key on the property, purchased from the local hardware store. It’s recommended that the seller verify the buyers identify on social media and obtain their professional license number before sharing the lockbox code. The seller may want to monitor the buyer’s entry and exit times as scheduled and change the lockbox code regularly"
                                    ></b-form-textarea>
                                </b-form-group>
                                </div>
                            </div>
                            <!-- <div class="row">
                                <div class="col-md-6">
                                    <validation-provider
                                        :rules="{ required: true,regex:/^(\+|-)?(?:90(?:(?:\.0{1,14})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,14})?))$/}"
                                    >
                                        <b-form-group
                                            class="userNameFormGroup"
                                            label-for="latitude"
                                            slot-scope="{ failedRules }"
                                        >
                                        <template #label>
                                            Latitude <span class="errorAasterisk">*</span>
                                        </template>
                                            <b-form-input
                                            class="InputFormClass"
                                            v-model="formData.leaseBLatitude"
                                            type="text"
                                            id="latittude"
                                            placeholder="Enter latitude"
                                            ></b-form-input>
                                            <div v-if="failedRules.regex || failedRules.required">
                                                <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                                <small class="text-danger error" v-if="failedRules.regex">Please provide valid value.</small>
                                            </div>
                                            <span style="display: block !important;margin-top:15px !important;" class="accuracy">PS: For more accuracy of address on map enter latitude/longitude</span>
                                        </b-form-group>
                                    </validation-provider>
                                </div>
                                <div class="col-md-6">
                                    <validation-provider
                                        :rules="{ required: true,regex:/^(\+|-)?(?:180(?:(?:\.0{1,14})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,14})?))$/}"
                                    >
                                        <b-form-group
                                            class="userNameFormGroup"
                                            label-for="longitude"
                                            slot-scope="{ failedRules }"
                                        >
                                        <template #label>
                                            Longitude <span class="errorAasterisk">*</span>
                                        </template>
                                            <b-form-input
                                            class="InputFormClass"
                                            v-model="formData.leaseBLongitude"
                                            type="text"
                                            id="longitude"
                                            placeholder="Enter longitude"
                                            ></b-form-input>
                                            <div v-if="failedRules.regex || failedRules.required">
                                                <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                                <small class="text-danger error" v-if="failedRules.regex">Please provide valid value.</small>
                                            </div>
                                            <span style="display: block !important;margin-top:15px !important;" class="Get_long"><a target="_blank" href="https://www.latlong.net/">Click here to get lat/long</a></span>
                                        </b-form-group>
                                    </validation-provider>
                                </div>
                            </div> -->
                            <div class="row">
                                <div class="col-md-6 selectWrapper">
                                    <label>Shelter Type <span class="errorAasterisk">*</span></label>
                                    <div class="customerStyle">
                                        <validation-provider :rules="{ required: true}">
                                            <div slot-scope="{ failedRules }">
                                                <!-- <select class="selectCustomer" v-model="formData.leaseBSelectPropertyType" >
                                                    <option value="" hidden disabled>Select</option>
                                                    <option :value="pType" v-for="(pType,ind) in propertyTypeArray" :key='ind'>{{pType}}</option>
                                                </select> -->
                                                <MultiSelect placeholder="Shelter Type" v-model="formData.leaseBSelectPropertyType" :multiple="true" :showPointer="false" :limit="1" :close-on-select="false" :options="propertyTypeArray"></MultiSelect>
                                                <small>You may select more than one</small>
                                                <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                            </div>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label class="radioBoxLabel">Housing Type <span class="errorAasterisk">*</span></label>
                                    <div class="d-flex">
                                        <div class="radio-housing-type">
                                            <input type="radio" id="openConcetShelter" value="Open Concept Shelter" name="radio-group-Confition-houseing-type" v-model="formData.leaseBHouseingType">
                                            <label for="openConcetShelter">Open Concept Shelter</label>
                                        </div>
                                        <div class="radio-housing-type">
                                            <input type="radio" id="sharedHousePrivate" value="Shared House (Private Room)" name="radio-group-Confition-houseing-type" v-model="formData.leaseBHouseingType">
                                            <label for="sharedHousePrivate">Shared House (Private Room)</label>
                                        </div> 
                                    </div>
                                    <div class="radioWrapper d-flex">
                                        <div class="radio-housing-type">
                                            <input type="radio" id="sharedRoomDouble" value="Shared Room (Double Occupancy)" name="radio-group-Confition-houseing-type" v-model="formData.leaseBHouseingType">
                                            <label for="sharedRoomDouble">Shared Room (Double Occupancy)</label>
                                        </div> 
                                        <div class="radio-housing-type">
                                            <input type="radio" id="sharedRoomTriple" value="Shared Room (Triple Occupancy)" name="radio-group-Confition-houseing-type" v-model="formData.leaseBHouseingType">
                                            <label for="sharedRoomTriple">Shared Room (Triple Occupancy)</label>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="row bathroomGaradgeCountRow">
                                <div class="col-md-6">
                                    <validation-provider :rules="{ required: true,regex:/^(5|[0-9]+(\.[05])?)$/}">
                                        <b-form-group
                                            class="userNameFormGroup"
                                            label-for="input-1"
                                             slot-scope="{ failedRules }"
                                        >
                                            <template #label>
                                                Bedroom Count <span class="errorAasterisk">*</span>
                                            </template>
                                                <b-form-input
                                                class="InputFormClass"
                                                v-model="formData.leaseBBedroomCount"
                                                type="text"
                                                placeholder="Enter bedroom count"
                                                maxLength="10"
                                                autocomplete="off"
                                                ></b-form-input>
                                                <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                                <small class="text-danger error" v-if="failedRules.regex">Please provide valid value.</small>
                                        </b-form-group>
                                    </validation-provider>
                                </div>
                                <div class="col-md-6">
                                    <validation-provider :rules="{ required: true,regex:/^(5|[0-9]+(\.[05])?)$/}">
                                        <b-form-group
                                            class="userNameFormGroup"
                                            label-for="input-1"
                                            slot-scope="{ failedRules }"
                                        >
                                            <template #label>
                                                Bathroom Count <span class="errorAasterisk">*</span>
                                            </template>
                                            <b-form-input
                                            class="InputFormClass"
                                            v-model="formData.leaseBBathroomCount"
                                            type="text"
                                            maxLength="10"
                                            autocomplete="off"
                                            placeholder="Enter bathroom count"
                                            
                                            ></b-form-input>
                                            <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                            <small class="text-danger error" v-if="failedRules.regex">Please provide valid value.</small>
                                        </b-form-group>
                                    </validation-provider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <validation-provider :rules="{ required: true,regex:/^\d+$/}">
                                        <b-form-group
                                            class="userNameFormGroup"
                                            label="Garage Car Count"
                                            label-for="input-1"
                                            slot-scope="{ failedRules }"
                                        >
                                        <template #label>
                                            Garage Car Count <span class="errorAasterisk">*</span>
                                        </template>
                                            <b-form-input
                                            class="InputFormClass"
                                            v-model="formData.leaseBGarageCarCount"
                                            type="text"
                                            placeholder="Enter garage car count"
                                            maxLength="10"
                                            autocomplete="off"
                                            ></b-form-input>
                                            <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                            <small class="text-danger error" v-if="failedRules.regex">Please provide valid value.</small>
                                        </b-form-group>
                                    </validation-provider>
                                </div>
                                <div class="col-md-6">
                                    <validation-provider :rules="{ required: true,regex:/^\d+$/}">
                                        <b-form-group
                                            class="userNameFormGroup"
                                            label-for="input-1"
                                            slot-scope="{ failedRules }"
                                        >
                                            <template #label>
                                                Indoor Living Square Foot <span class="errorAasterisk">*</span>
                                            </template>
                                            <b-form-input
                                                class="InputFormClass"
                                                v-model="formData.leaseBIndoorLivingSquareFoot"
                                                type="text"
                                                placeholder="Enter indoor living square foot"
                                                autocomplete="off"
                                                maxLength="10"
                                            ></b-form-input>
                                            <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                            <small class="text-danger error" v-if="failedRules.regex">Please provide valid value.</small>
                                        </b-form-group>
                                    </validation-provider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <validation-provider :rules="{ required: true,regex:/^\d+$/}">
                                        <b-form-group
                                            class="userNameFormGroup"
                                            label-for="input-1"
                                            slot-scope="{ failedRules }"
                                        >
                                            <template #label>
                                                Lot Size <span class="errorAasterisk">*</span>
                                            </template>
                                            <b-form-input
                                            class="InputFormClass"
                                            v-model="formData.leaseBLotSize"
                                            type="text"
                                            placeholder="Enter lot size"
                                            autocomplete="off"
                                            maxLength="10"
                                            ></b-form-input>
                                            <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                            <small class="text-danger error" v-if="failedRules.regex">Please provide valid value.</small>
                                        </b-form-group>
                                    </validation-provider>
                                </div>
                                <div class="col-md-6">
                                    <!-- <validation-provider :rules="{min:4,regex:/^\d+$/}"> -->
                                        <b-form-group
                                            class="userNameFormGroup"
                                            label-for="input-1"
                                        >
                                        <!-- <b-form-group
                                            class="userNameFormGroup"
                                            label-for="input-1"
                                        > -->
                                            <template #label>
                                                Year Updated
                                            </template>
                                            <b-form-input
                                            class="InputFormClass"
                                            v-model="formData.leaseBYearBuilt"
                                            type="text"
                                            maxLength="4"
                                            placeholder="Enter year built"
                                            ></b-form-input>
                                            <!-- <small class="text-danger error" v-if="failedRules.required">The field is required.</small> -->
                                            <!-- <small class="text-danger error" v-if="failedRules.min">Please provide valid value.</small> -->
                                            <!-- <small class="text-danger error" v-if="failedRules.regex">Please provide valid value.</small> -->
                                        </b-form-group>
                                    <!-- </validation-provider> -->
                                </div>
                                <!-- <div class="col-md-6">
                                    <validation-provider :rules="{ required: true}">
                                        <b-form-group
                                            class="userNameFormGroup"
                                            label-for="input-1"
                                             slot-scope="{ failedRules }"
                                        >
                                        <template #label>
                                            Roof <span class="errorAasterisk">*</span>
                                        </template>
                                            <b-form-input
                                            class="InputFormClass"
                                            v-model="formData.leaseBRoof"
                                            type="text"
                                            placeholder="Enter roof description"
                                            maxLength="100"
                                            ></b-form-input>
                                            <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                        </b-form-group>
                                    </validation-provider>
                                </div> -->
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <label>Bathroom Type <span class="errorAasterisk">*</span></label>
                                    <div class="radioWrapper d-flex">
                                        <div class="radio">
                                            <input type="radio" id="sharedBathroom" value="Shared" name="radio-group-Confition-bathroom-type" v-model="formData.leaseBBathroomType">
                                            <label for="sharedBathroom">Shared</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" id="privateBathroom" value="Private" name="radio-group-Confition-bathroom-type" v-model="formData.leaseBBathroomType">
                                            <label for="privateBathroom">Private</label>
                                        </div> 
                                    </div>
                                </div>
                                <div class="col-md-6 selectWrapper">
                                    <label>Monthly Price Per Person <span class="errorAasterisk">*</span></label>
                                    <div class="customerStyle">
                                        <validation-provider :rules="{ required: true}">
                                            <div slot-scope="{ failedRules }">
                                                <MultiSelect v-model="formData.leaseBSelectMonthlyPricePerPerson" :multiple="true" :showPointer="false" :limit="1" :close-on-select="false" :options="monthlyPerPersonPrice"></MultiSelect>
                                                <small>You may select more than one</small>
                                                <small class="text-danger" v-if="failedRules.required">The field is required.</small>
                                            </div>
                                        </validation-provider>
                                    </div>
                                </div>
                                <!-- <div class="col-md-6">
                                    <validation-provider :rules="{ required: true}">
                                        <b-form-group
                                            class="userNameFormGroup"
                                            label-for="input-1"
                                            slot-scope="{ failedRules }"
                                        >
                                        <template #label>
                                            HVAC <span class="errorAasterisk">*</span>
                                        </template>
                                            <b-form-input
                                            class="InputFormClass"
                                            v-model="formData.leaseBHVAC"
                                            type="text"
                                            maxLength="100"
                                            placeholder="Enter HVAC description"
                                            ></b-form-input>
                                            <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                        </b-form-group>
                                    </validation-provider>
                                </div> -->
                                <!-- <div class="col-md-6">
                                    <validation-provider :rules="{ required: true}">
                                        <b-form-group
                                            class="userNameFormGroup"
                                            label-for="input-1"
                                             slot-scope="{ failedRules }"
                                        >
                                        <template #label>
                                            Flooring <span class="errorAasterisk">*</span>
                                        </template>
                                            <b-form-input
                                            class="InputFormClass"
                                            v-model="formData.leaseBFlooring"
                                            type="text"
                                            placeholder="Enter flooring description"
                                            maxLength="100"
                                            ></b-form-input>
                                            <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                        </b-form-group>
                                    </validation-provider>
                                </div> -->
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <label class="radioBoxLabel">Are utilities included in the monthly cost? <span class="errorAasterisk">*</span></label>
                                    <div class="radioWrapper d-flex">
                                        <div class="radio">
                                            <input type="radio" id="yesUtilitiCost" value="Yes" name="radio-group-Confition-utilitiescost-type" v-model="formData.leaseBUtilitiesCost">
                                            <label for="yesUtilitiCost">Yes</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" id="noUtilitiesCost" value="No" name="radio-group-Confition-utilitiescost-type" v-model="formData.leaseBUtilitiesCost">
                                            <label for="noUtilitiesCost">No</label>
                                        </div> 
                                        <div class="radio">
                                            <input type="radio" id="naUtilitiesCost" value="N/A" name="radio-group-Confition-utilitiescost-type" v-model="formData.leaseBUtilitiesCost">
                                            <label for="naUtilitiesCost">N/A</label>
                                        </div> 
                                    </div>
                                </div>
                                <div class="col-md-6 selectWrapper">
                                    <label>Entry Fee <span class="errorAasterisk">*</span></label>
                                    <div class="customerStyle">
                                        <validation-provider :rules="{ required: true}">
                                            <div slot-scope="{ failedRules }">
                                                <select class="selectCustomer" v-model="formData.leaseBEntryFee">
                                                    <option value="" hidden disabled>Select</option>
                                                    <option :value="i" v-for="(i,ind) in optionsEntryFee" :key='ind'>{{i}}</option>
                                                </select>
                                                <!-- <MultiSelect v-model="formData.leaseBEntryFee" :multiple="true" :showPointer="false" :limit="1" :close-on-select="false" :options="optionsEntryFee"></MultiSelect> -->
                                                <small class="text-danger" v-if="failedRules.required">The field is required.</small>
                                            </div>
                                        </validation-provider>
                                    </div>
                                </div>
                                <!-- <div class="col-md-6">
                                    <validation-provider :rules="{ required: true}">
                                        <b-form-group
                                            class="userNameFormGroup"
                                            label-for="input-1"
                                            slot-scope="{ failedRules }"
                                        >
                                        <template #label>
                                            Windows <span class="errorAasterisk">*</span>
                                        </template>
                                            <b-form-input
                                            class="InputFormClass"
                                            v-model="formData.leaseBWindows"
                                            type="text"
                                            placeholder="Enter windows description"
                                            maxLength="100"
                                            ></b-form-input>
                                            <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                        </b-form-group>
                                    </validation-provider>
                                </div>
                                <div class="col-md-6">
                                    <validation-provider :rules="{ required: true}">
                                        <b-form-group
                                            class="userNameFormGroup"
                                            label-for="input-1"
                                             slot-scope="{ failedRules }"
                                        >
                                        <template #label>
                                            Plumbing  <span class="errorAasterisk">*</span>
                                        </template>
                                            <b-form-input
                                            class="InputFormClass"
                                            v-model="formData.leaseBPlumbing"
                                            type="text"
                                            placeholder="Enter plumbing description"
                                            maxLength="100"
                                            ></b-form-input>
                                            <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                        </b-form-group>
                                    </validation-provider>
                                </div> -->
                            </div>
                            <div class="row">
                                <div class="col-md-6 selectWrapper">
                                    <label>Acceptable Payment Methods <span class="errorAasterisk">*</span></label>
                                    <div class="customerStyle">
                                        <validation-provider :rules="{ required: true}">
                                            <div slot-scope="{ failedRules }">
                                                <MultiSelect v-model="formData.leaseBPaymentMethod" :multiple="true" :showPointer="false" :limit="1" :close-on-select="false" :options="optionsPaymentMethods"></MultiSelect>
                                                <small>You may select more than one</small>
                                                <small class="text-danger" v-if="failedRules.required">The field is required.</small>
                                            </div>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div class="col-md-6 selectWrapper">
                                    <label>Age <span class="errorAasterisk">*</span></label>
                                    <div class="customerStyle">
                                        <validation-provider :rules="{ required: true}">
                                            <div slot-scope="{ failedRules }">
                                                <MultiSelect v-model="formData.leaseBAge" :multiple="true" :showPointer="false" :limit="1" :close-on-select="false" :options="optionsAge"></MultiSelect>
                                                <small>You may select more than one</small>
                                                <small class="text-danger" v-if="failedRules.required">The field is required.</small>
                                            </div>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 selectWrapper">
                                    <label>Gender(s) Admitted <span class="errorAasterisk">*</span></label>
                                    <div class="customerStyle">
                                        <validation-provider :rules="{ required: true}">
                                            <div slot-scope="{ failedRules }">
                                                <MultiSelect v-model="formData.leaseBGender" :multiple="true" :showPointer="false" :limit="1" :close-on-select="false" :options="optionsGender"></MultiSelect>
                                                <small>You may select more than one</small>
                                                <small class="text-danger" v-if="failedRules.required">The field is required.</small>
                                            </div>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div class="col-md-6 selectWrapper">
                                    <label>Length of Stay <span class="errorAasterisk">*</span></label>
                                    <div class="customerStyle">
                                        <validation-provider :rules="{ required: true}">
                                            <div slot-scope="{ failedRules }">
                                                <MultiSelect v-model="formData.leaseBLengthOfStay" :multiple="true" :showPointer="false" :limit="1" :close-on-select="false" :options="optionsLengthOfStay"></MultiSelect>
                                                <small>You may select more than one</small>
                                                <small class="text-danger" v-if="failedRules.required">The field is required.</small>
                                            </div>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row">
                                <div class="col-md-6">
                                    <validation-provider :rules="{ required: true}">
                                        <b-form-group
                                            class="userNameFormGroup"
                                            label-for="input-1"
                                             slot-scope="{ failedRules }"
                                        >
                                        <template #label>
                                            Electrical  <span class="errorAasterisk">*</span>
                                        </template>
                                            <b-form-input
                                            class="InputFormClass"
                                            v-model="formData.leaseBElectrical"
                                            type="text"
                                            placeholder="Enter electrical description"
                                            maxLength="100"
                                            ></b-form-input>
                                            <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                        </b-form-group>
                                    </validation-provider>
                                </div>
                                <div class="col-md-6">
                                    <validation-provider :rules="{ required: true}">
                                        <b-form-group
                                            class="userNameFormGroup"
                                            label-for="input-1"
                                            slot-scope="{ failedRules }"
                                        >
                                        <template #label>
                                            Parking Features <span class="errorAasterisk">*</span>
                                        </template>
                                            <b-form-input
                                            class="InputFormClass"
                                            v-model="formData.leaseBParkingFeatures"
                                            type="text"
                                            placeholder="Enter parking features description"
                                            maxLength="100"
                                            ></b-form-input>
                                            <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                        </b-form-group>
                                    </validation-provider>
                                </div>
                            </div> -->
                            <!-- <div class="row">
                                <div class="col-md-6">
                                    <validation-provider :rules="{ required: true}">
                                        <b-form-group
                                            class="userNameFormGroup"
                                            label-for="input-1"
                                            slot-scope="{ failedRules }"
                                        >
                                        <template #label>
                                            Foundation <span class="errorAasterisk">*</span>
                                        </template>
                                            <b-form-input
                                            class="InputFormClass"
                                            v-model="formData.leaseBFoundation"
                                            type="text"
                                            placeholder="Enter foundation description"
                                            maxLength="100"
                                            ></b-form-input>
                                            <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                        </b-form-group>
                                    </validation-provider>
                                </div>
                                <div class="col-md-6 selectWrapper">
                                    <label>Sewer information <span class="errorAasterisk">*</span></label>
                                    <div class="customerStyle">
                                        <validation-provider :rules="{ required: true}">
                                            <div slot-scope="{ failedRules }">
                                                <select class="selectCustomer" v-model="formData.leaseBSewerInformation">
                                                    <option value="" hidden disabled>Select sewer</option>
                                                    <option value="municipal">Municipal</option>
                                                    <option value="private septic">Private Septic</option>
                                                    <option value="unknown">Unknown</option>
                                                </select>
                                                <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                            </div>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 selectWrapper">
                                    <label>Water information <span class="errorAasterisk">*</span></label>
                                    <div class="customerStyle">
                                    <validation-provider :rules="{ required: true}">
                                        <div slot-scope="{ failedRules }">
                                        <select class="selectCustomer" v-model="formData.leaseBWaterInformation">
                                            <option value="" hidden disabled>Select</option>
                                            <option value="municipal">Municipal</option>
                                            <option value="private well">Private Well</option>
                                            <option value="unknown">Unknown</option>
                                        </select>
                                        <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                        </div>
                                    </validation-provider>
                                    </div>
                                </div>
                                <div class="col-md-6 ">
                                <b-form-group
                                    class="userNameFormGroup"
                                    label-for="input-1"
                                >
                                <template class="label">
                                        <div class="countWrapper">
                                            Highlight #1 
                                        <div class="textAreaCount">
                                            <span class="textCount">{{formData.leaseBHighlight1 ? formData.leaseBHighlight1.length: 0}}/50</span>
                                    </div>
                                    </div>
                                    </template>
                              
                                    <b-form-input
                                    class="InputFormClass"
                                    v-model="formData.leaseBHighlight1"
                                    type="text"
                                    placeholder="Enter highlight #1"
                                    maxLength="50"
                                    ></b-form-input>
                                </b-form-group>
                                </div>
                            </div> -->
                            <!-- <div class="row highlightTwoOtherCommentRow">
                                <div class="col-md-6">
                                <b-form-group
                                    class="userNameFormGroup"
                                    label-for="input-1"
                                >
                                <template class="label">
                                        <div class="countWrapper">
                                            Highlight #2 
                                        <div class="textAreaCount">
                                            <span class="textCount">{{formData.leaseBHighlight2 ? formData.leaseBHighlight2.length: 0}}/50</span>
                                    </div>
                                    </div>
                                    </template>
                              
                                    <b-form-input
                                    class="InputFormClass"
                                    v-model="formData.leaseBHighlight2"
                                    type="text"
                                    placeholder="Enter highlight #2"
                                    maxLength="50"
                                    ></b-form-input>
                                </b-form-group>
                                </div>
                                <div class="col-md-6">
                                <b-form-group
                                    class="userNameFormGroup"
                                    label-for="input-1"
                                >
                                <template class="label">
                                        <div class="countWrapper">
                                        Other Comments 
                                        <div class="textAreaCount">
                                            <span class="textCount">{{formData.leaseBOtherComments ? formData.leaseBOtherComments.length: 0}}/50</span>
                                    </div>
                                    </div>
                                    </template>
                               
                                    <b-form-input
                                    class="InputFormClass"
                                    v-model="formData.leaseBOtherComments"
                                    type="text"
                                    placeholder="Enter other comments"
                                    maxLength="50"
                                    ></b-form-input>
                                </b-form-group>
                                </div>
                            </div>
                            <div class="row radioBox daysCount">
                                <div class="col-md-12">
                                    <label class="radioBoxLabel">Is the property in livable condition (has working heating, electricity, water, And no health or safety concerns)? <span class="errorAasterisk">*</span></label>
                                    <div class="radioWrapper d-flex">
                                        <div class="radio">
                                            <input type="radio" id="Islivabletoday" value="Is livable today" name="radio-group-data-value-add" v-model="formData.leaseBIsPropertyInLivableConfition">
                                            <label for="Islivabletoday">Is livable today</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" id="Isnotlivabletoday" value="Is not livable today" name="radio-group-data-value-add" v-model="formData.leaseBIsPropertyInLivableConfition">
                                            <label for="Isnotlivabletoday">Is not livable today</label>
                                        </div> 
                                        <div class="radio">
                                            <input type="radio" id="Unknown" value="Unknown" name="radio-group-data-value-add" v-model="formData.leaseBIsPropertyInLivableConfition">
                                            <label for="Unknown">Unknown</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                <label>Is this a mortgage note or a lease option for sale? <span class="errorAasterisk">*</span></label>
                                <div class="radioWrapper d-flex">
                                    <div class="radio">
                                        <input type="radio" id="MortgageNote" value="mortgage note" name="radio-group-data-value" v-model="formData.leaseBMortgageNoteOrALeaseOptionForSale">
                                        <label for="MortgageNote">Mortgage Note</label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" id="LeaseOption" value="lease option" name="radio-group-data-value" v-model="formData.leaseBMortgageNoteOrALeaseOptionForSale">
                                        <label for="LeaseOption">Lease Option</label>
                                    </div>
                                    <div class="radio_lease">
                                        <input type="radio" id="leaseoption2" value="contract for deed" name="radio-group-data-value" v-model="formData.leaseBMortgageNoteOrALeaseOptionForSale">
                                        <label for="leaseoption2">Contract for Deed</label>
                                    </div> 
                                </div>
                                </div>
                            </div> -->
                            <!-- Property Detail End -->
                            <!-- For Intern Use Only Start -->
                            <div class="forInternalViewForm">
                                <h5 class="forInternUseHeading">FOR INTERNAL USE ONLY</h5>
                                <div class="nameofOwnerDiv">
                                    <validation-provider :rules="{ required: true}">
                                        <b-form-group
                                                label-for="input-1"
                                                slot-scope="{ failedRules }"
                                            >
                                            <template #label>
                                                What is the EXTERNAL nickname for this shelter location? <span class="errorAasterisk">*</span>
                                            </template>
                                            <b-form-input
                                                class="InputFormClass"
                                                v-model="formData.leaseAShelterName"
                                                type="text"
                                                placeholder="Enter is the EXTERNAL nickname for this shelter location"
                                                maxLength="100"
                                            >
                                            </b-form-input>
                                            <small class="text-danger" v-if="failedRules.required">The field is required.</small>
                                        </b-form-group>
                                    </validation-provider>
                                </div>
                                <div class="nameofOwnerDiv">
                                    <validation-provider :rules="{ required: true}">
                                        <b-form-group
                                                label-for="input-1"
                                                slot-scope="{ failedRules }"
                                            >
                                            <template #label>
                                                Who is the best point of contact? <span class="errorAasterisk">*</span>
                                            </template>
                                            <b-form-input
                                                class="InputFormClass"
                                                v-model="formData.leaseALanderNameOnTitle"
                                                type="text"
                                                placeholder="Enter the best point of contact"
                                                maxLength="100"
                                            >
                                            </b-form-input>
                                            <small class="text-danger" v-if="failedRules.required">The field is required.</small>
                                        </b-form-group>
                                    </validation-provider>
                                </div>
                                <div class="nameofOwnerDiv">
                                    <validation-provider :rules="{ required: true}">
                                        <b-form-group
                                                label-for="input-1"
                                                slot-scope="{ failedRules }"
                                            >
                                            <template #label>
                                                What is the best phone number for the point of contact? <span class="errorAasterisk">*</span>
                                            </template>
                                            <b-form-input
                                                class="InputFormClass"
                                                v-model="formData.leaseALanderBestPhoneNumber"
                                                type="text"
                                                placeholder="Enter the best phone number for the point of contact"
                                                maxLength="100"
                                            >
                                            </b-form-input>
                                            <small class="text-danger" v-if="failedRules.required">The field is required.</small>
                                        </b-form-group>
                                    </validation-provider>
                                </div>
                                <!-- <label>Are you properly licensed and insured to operate this location for its intended use? <span class="errorAasterisk">*</span></label>
                                <div class="radioWrapper d-flex">
                                    <div class="radio">
                                        <input type="radio" id="yesData" :value="true" name="radio-group-data" v-model="formData.leaseAIsPropertyHasMorgageNoteOrLeaseOption">
                                        <label for="yesData">Yes</label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" id="noData" :value="false" name="radio-group-data" v-model="formData.leaseAIsPropertyHasMorgageNoteOrLeaseOption">
                                        <label for="noData">No</label>
                                    </div> 
                                </div>
                                <div class="nameofOwnerDiv">
                                    <validation-provider :rules="{ required: true}">
                                        <b-form-group
                                                label-for="input-1"
                                                slot-scope="{ failedRules }"
                                                class="userNameFormGroup"
                                            >
                                            <template #label>
                                                Who is the owner of this location if we have an internal question? <span class="errorAasterisk">*</span>
                                            </template>
                                            <b-form-input
                                                class="InputFormClass"
                                                v-model="formData.leaseALanderNameOnTitle"
                                                type="text"
                                                placeholder="Enter name of the lender"
                                                maxLength="100"
                                            >
                                            </b-form-input>
                                            <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                        </b-form-group>
                                    </validation-provider>
                                </div> -->
                                <!-- <div class="dropDownwrapper form-group userNameFormGroup">
                                    <div class="dropDownCompnayState forInternalnotapplicable d-flex justify-content-between forSaleCheckBox">
                                        <label  class="notAppliLabel">What state was the company formed in, if the mortgage note is owned by a company? <span class="errorAasterisk">*</span></label>
                                        <div class="checBox notapplicableCheckBox">
                                            <div class="form-group-chekbox">
                                                <input @click="companyName()" type="checkbox" id="wholesaler" v-model="formData.leaseAIsMortgageNoteOwnedByCompanyStateWhereCompanyFormedIn" />
                                                <label for="wholesaler" class="checkBoxLabel">N/A</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="customerStyle forInternOnly">
                                       
                                        <validation-provider
                                            :rules="{ required:(formData.leaseAIsMortgageNoteOwnedByCompanyStateWhereCompanyFormedIn == false)}"
                                        >
                                            <div slot-scope="{ failedRules }" class="form-group-inputRequired">
                                                <select class="selectCustomer" v-model="formData.leaseAMortgageNoteOwnedByCompanyStateWhereCompanyFormedIn" :disabled="formData.leaseAIsMortgageNoteOwnedByCompanyStateWhereCompanyFormedIn">
                                                    <option value="" disabled>Enter the state where the company was formed</option>
                                                    <option :value="State" v-for="(State,ind) in stateOfUseAndCanada" :key='ind'>{{State}}</option>
                                                </select>
                                                <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                            </div>
                                        </validation-provider>
                                       
                                    </div>
                                </div> -->
                                <!-- <div class="nameofOwnerDiv">
                                    <div class="dropDownCompnayState forInternalnotapplicable d-flex justify-content-between forSaleCheckBox listMortgage">
                                        <label  class="notAppliLabel">What is the best phone number to reach the owner of this location? <span class="errorAasterisk">*</span></label>
                                        <div class="checBox notapplicableCheckBox">  
                                            <div class="form-group-chekbox">
                                                <input @click="companyNameValue()" type="checkbox" id="wholesalerValue" v-model="formData.leaseAIsMortgageNoteOwnedByCompanyOperatorsOfTheCompany"/>
                                                <label for="wholesalerValue" class="checkBoxLabel">N/A</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group userNameFormGroup">
                                    <validation-provider
                                        :rules="{ required:(formData.leaseAIsMortgageNoteOwnedByCompanyOperatorsOfTheCompany == false)}"
                                    >
                                        <b-form-group label-for="input-1" slot-scope="{ failedRules }">
                                            <b-form-input
                                                class="InputFormClass"
                                                v-model="formData.leaseAMortgageNoteOwnedByCompanyOperatorsOfTheCompany"
                                                type="text"
                                                placeholder="Enter name of the operators"
                                                maxLength="100"
                                                :disabled="formData.leaseAIsMortgageNoteOwnedByCompanyOperatorsOfTheCompany"
                                            >
                                            </b-form-input>
                                            <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                        </b-form-group>
                                    </validation-provider>
                                    </div>
                                </div> -->
                                <!-- <label>Are you a note broker with a signed agreement, listing on behalf of the seller? <span class="errorAasterisk">*</span></label>
                                <div class="radioWrapper d-flex">
                                    <div class="radio">
                                        <input type="radio" id="yesName" :value="true" name="radio-group-name" v-model="formData.leaseAAreYouABrokerWithSignedAgreement">
                                        <label for="yesName">Yes</label>
                                    </div>
                                    <div class="radio">
                                        <input type="radio" id="noName" :value="false" name="radio-group-name" v-model="formData.leaseAAreYouABrokerWithSignedAgreement">
                                        <label for="noName">No</label>
                                    </div> 
                                </div> -->
                            </div>
                            <!-- For Intern Use Only end -->
                            <!-- Landing  Info Form start -->
                            <div class="lendingInfoviewForm">
                            <h5 class="forInternUseHeading">FEATURES</h5>
                            <div class="row">
                                <div class="col-md-6">
                                    <validation-provider :rules="{ required: true}">
                                        <b-form-group
                                            class="userNameFormGroup"
                                            label-for="input-1"
                                            slot-scope="{ failedRules }"
                                        >
                                            <template #label>
                                                Languages Spoken at the Shelter <span class="errorAasterisk">*</span>
                                            </template>
                                            <b-form-input
                                            class="InputFormClass"
                                            v-model="formData.leaseCLanguageSpokenAtShelter"
                                            type="text"
                                            placeholder="Enter languages spoken at the shelter"
                                            autocomplete="off"
                                            ></b-form-input>
                                            <small class="text-danger" v-if="failedRules.required">The field is required.</small>
                                        </b-form-group>
                                    </validation-provider>
                                </div>
                                <div class="col-md-6">
                                    <label>Security System <span class="errorAasterisk">*</span></label>
                                    <div class="radioWrapper d-flex">
                                        <div class="radio">
                                            <input type="radio" id="yesSecuritySystem" value="Yes" name="radio-group-Confition-security-system" v-model="formData.leaseCSecuritySystem">
                                            <label for="yesSecuritySystem">Yes</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" id="noSecuritySystem" value="No" name="radio-group-Confition-security-system" v-model="formData.leaseCSecuritySystem">
                                            <label for="noSecuritySystem">No</label>
                                        </div> 
                                    </div>
                                </div>
                                <!-- <div class="col-md-6">
                                    <b-form-group
                                        class="userNameFormGroup"
                                        id="inputwithCheckBoxFormGroup"
                                        label-for="annualhOA"
                                    >
                                        <div class="dropDownCompnayState d-flex annualAmountWrapper">
                                            <label class="annualAcnt">Annual HOA Amount ($) <span class="errorAasterisk">*</span></label>
                                            <div class="checBox notapplicableCheckBox anualListViewPage">  
                                                
                                                <div class="form-group-chekbox">
                                                    <input @click="companyValue()" type="checkbox" id="notapplicabale" v-model="formData.leaseCIsAnnualHOAAmount" />
                                                    <label for="notapplicabale" class="checkBoxLabel">N/A</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group-inputRequired">
                                            <validation-provider
                                                :rules="formData.leaseCIsAnnualHOAAmount == false ? {required:true,regex:/^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/} : ''"
                                            >
                                                <div slot-scope="{ failedRules }" class="form-group-inputRequired">
                                                    <b-form-input :disabled="formData.leaseCIsAnnualHOAAmount" 
                                                    autocomplete="off"
                                                    type="text" class="InputFormClass" placeholder="Enter annual HOA amount" v-model="formData.leaseCAnnualHOAAmount" />
                                                    <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                                    <small class="text-danger error" v-if="failedRules.regex">Please provide valid value.</small>
                                                </div>
                                            </validation-provider>
                                        </div>
                                    </b-form-group>
                                </div> -->
                                <!-- <div class="col-md-6">
                                    <validation-provider :rules="{ required: true,regex:/^[0-9]+$|^[0-9]+[.]?[0-9]+[0-9]*$/}">
                                        <b-form-group
                                            class="userNameFormGroup"
                                            label-for="annualtax"
                                            slot-scope="{ failedRules }"
                                        >
                                            <template #label>
                                                Annual Tax Amount ($) <span class="errorAasterisk">*</span>
                                            </template>
                                            <b-form-input
                                            class="InputFormClass"
                                            v-model="formData.leaseCAnnualTaxAmount"
                                            type="text"
                                            placeholder="Enter annual tax amount"
                                            autocomplete="off"
                                            ></b-form-input>
                                            <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                            <small class="text-danger error" v-if="failedRules.regex">Please provide valid value.</small>
                                        </b-form-group>
                                    </validation-provider>
                                </div> -->
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <label>Rooms Can be Locked <span class="errorAasterisk">*</span></label>
                                    <div class="radioWrapper d-flex">
                                        <div class="radio">
                                            <input type="radio" id="yesRoomLocked" value="Yes" name="radio-group-room-locked" v-model="formData.leaseCRoomLocked">
                                            <label for="yesRoomLocked">Yes</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" id="noRoomLocked" value="No" name="radio-group-room-locked" v-model="formData.leaseCRoomLocked">
                                            <label for="noRoomLocked">No</label>
                                        </div> 
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Transportation Provided <span class="errorAasterisk">*</span></label>
                                    <div class="radioWrapper d-flex">
                                        <div class="radio">
                                            <input type="radio" id="yesTransportationProvided" value="Yes" name="radio-group-trasportaion-provided" v-model="formData.leaseCTransportationProvided">
                                            <label for="yesTransportationProvided">Yes</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" id="noTransportationProvided" value="No" name="radio-group-trasportaion-provided" v-model="formData.leaseCTransportationProvided">
                                            <label for="noTransportationProvided">No</label>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <label>Bed and Furniture Provided <span class="errorAasterisk">*</span></label>
                                    <div class="radioWrapper d-flex">
                                        <div class="radio">
                                            <input type="radio" id="yesBedFurnitureProvided" value="Yes" name="radio-group-bed-furniture-provided" v-model="formData.leaseCBedFurnitureProvided">
                                            <label for="yesBedFurnitureProvided">Yes</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" id="noBedFurnitureProvided" value="No" name="radio-group-bed-furniture-provided" v-model="formData.leaseCBedFurnitureProvided">
                                            <label for="noBedFurnitureProvided">No</label>
                                        </div> 
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Food Provided <span class="errorAasterisk">*</span></label>
                                    <div class="radioWrapper d-flex">
                                        <div class="radio">
                                            <input type="radio" id="yesFoodProvided" value="Yes" name="radio-group-food-provided" v-model="formData.leaseCFoodProvided">
                                            <label for="yesFoodProvided">Yes</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" id="noFoodProvided" value="No" name="radio-group-food-provided" v-model="formData.leaseCFoodProvided">
                                            <label for="noFoodProvided">No</label>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <label>Appliances Provided <span class="errorAasterisk">*</span></label>
                                    <div class="radioWrapper d-flex">
                                        <div class="radio">
                                            <input type="radio" id="yesAppliancesProvided" value="Yes" name="radio-group-bed-appliances-provided" v-model="formData.leaseCAppliancesProvided">
                                            <label for="yesAppliancesProvided">Yes</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" id="noAppliancesProvided" value="No" name="radio-group-bed-appliances-provided" v-model="formData.leaseCAppliancesProvided">
                                            <label for="noAppliancesProvided">No</label>
                                        </div> 
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Internet Provided <span class="errorAasterisk">*</span></label>
                                    <div class="radioWrapper d-flex">
                                        <div class="radio">
                                            <input type="radio" id="yesInternetProvided" value="Yes" name="radio-group-internet-provided" v-model="formData.leaseCInternetProvided">
                                            <label for="yesInternetProvided">Yes</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" id="noInternetProvided" value="No" name="radio-group-internet-provided" v-model="formData.leaseCInternetProvided">
                                            <label for="noInternetProvided">No</label>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <label>Heating <span class="errorAasterisk">*</span></label>
                                    <div class="radioWrapper d-flex">
                                        <div class="radio">
                                            <input type="radio" id="yesHeating" value="Yes" name="radio-group-bed-heating-provided" v-model="formData.leaseCHeating">
                                            <label for="yesHeating">Yes</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" id="noHeating" value="No" name="radio-group-bed-heating-provided" v-model="formData.leaseCHeating">
                                            <label for="noHeating">No</label>
                                        </div> 
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Air Conditioning <span class="errorAasterisk">*</span></label>
                                    <div class="radioWrapper d-flex">
                                        <div class="radio">
                                            <input type="radio" id="yesAirConditioning" value="Yes" name="radio-group-air-conditioning" v-model="formData.leaseCAirConditioning">
                                            <label for="yesAirConditioning">Yes</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" id="noAirConditioning" value="No" name="radio-group-air-conditioning" v-model="formData.leaseCAirConditioning">
                                            <label for="noAirConditioning">No</label>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <label>Manager Onsite <span class="errorAasterisk">*</span></label>
                                    <div class="radioWrapper d-flex">
                                        <div class="radio">
                                            <input type="radio" id="yesHouseManager" value="Yes" name="radio-group-bed-house-manager" v-model="formData.leaseCHouseManager">
                                            <label for="yesHouseManager">Yes</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" id="noHouseManager" value="No" name="radio-group-bed-house-manager" v-model="formData.leaseCHouseManager">
                                            <label for="noHouseManager">No</label>
                                        </div> 
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Property Manager <span class="errorAasterisk">*</span></label>
                                    <div class="radioWrapper d-flex">
                                        <div class="radio">
                                            <input type="radio" id="yesPropertyManager" value="Yes" name="radio-group-property-manager" v-model="formData.leaseCPropertyManager">
                                            <label for="yesPropertyManager">Yes</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" id="noPropertyManager" value="No" name="radio-group-property-manager" v-model="formData.leaseCPropertyManager">
                                            <label for="noPropertyManager">No</label>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <label>Support Services in the Community <span class="errorAasterisk">*</span></label>
                                    <div class="radioWrapper d-flex">
                                        <div class="radio">
                                            <input type="radio" id="yesSupportServiceCommunity" value="Yes" name="radio-group-support-service-community" v-model="formData.leaseCSupportServiceCommunity">
                                            <label for="yesSupportServiceCommunity">Yes</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" id="noSupportServiceCommunity" value="No" name="radio-group-support-service-community" v-model="formData.leaseCSupportServiceCommunity">
                                            <label for="noSupportServiceCommunity">No</label>
                                        </div> 
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Entry-Level Work Nearby <span class="errorAasterisk">*</span></label>
                                    <div class="radioWrapper d-flex">
                                        <div class="radio">
                                            <input type="radio" id="yesEntryLevelWork" value="Yes" name="radio-group-entry-level-work" v-model="formData.leaseCEntryLevelWork">
                                            <label for="yesEntryLevelWork">Yes</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" id="noEntryLevelWork" value="No" name="radio-group-entry-level-work" v-model="formData.leaseCEntryLevelWork">
                                            <label for="noEntryLevelWork">No</label>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <label>Public Transportation Nearby <span class="errorAasterisk">*</span></label>
                                    <div class="radioWrapper d-flex">
                                        <div class="radio">
                                            <input type="radio" id="yesPublicTrasportation" value="Yes" name="radio-group-public-transportation" v-model="formData.leaseCPublicTrasportation">
                                            <label for="yesPublicTrasportation">Yes</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" id="noPublicTrasportation" value="No" name="radio-group-public-transportation" v-model="formData.leaseCPublicTrasportation">
                                            <label for="noPublicTrasportation">No</label>
                                        </div> 
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Valid ID Needed <span class="errorAasterisk">*</span></label>
                                    <div class="radioWrapper d-flex">
                                        <div class="radio">
                                            <input type="radio" id="yesValidIdNeeded" value="Yes" name="radio-group-entry-level-work-1" v-model="formData.leaseCValidIdNeeded">
                                            <label for="yesValidIdNeeded">Yes</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" id="noValidIdNeeded" value="No" name="radio-group-entry-level-work-1" v-model="formData.leaseCValidIdNeeded">
                                            <label for="noValidIdNeeded">No</label>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <label>Background Check <span class="errorAasterisk">*</span></label>
                                    <div class="radioWrapper d-flex">
                                        <div class="radio">
                                            <input type="radio" id="yesBackgroundCheck" value="Yes" name="radio-group-background-check" v-model="formData.leaseCBackgroundCheck">
                                            <label for="yesBackgroundCheck">Yes</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" id="noBackgroundCheck" value="No" name="radio-group-background-check" v-model="formData.leaseCBackgroundCheck">
                                            <label for="noBackgroundCheck">No</label>
                                        </div> 
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Credit Check <span class="errorAasterisk">*</span></label>
                                    <div class="radioWrapper d-flex">
                                        <div class="radio">
                                            <input type="radio" id="yesCreditCheck" value="Yes" name="radio-group-credit-check" v-model="formData.leaseCCreditCheck">
                                            <label for="yesCreditCheck">Yes</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" id="noCreditCheck" value="No" name="radio-group-credit-check" v-model="formData.leaseCCreditCheck">
                                            <label for="noCreditCheck">No</label>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <label>Social Security Number <span class="errorAasterisk">*</span></label>
                                    <div class="radioWrapper d-flex">
                                        <div class="radio">
                                            <input type="radio" id="yesSocialSecurityNumber" value="Yes" name="radio-group-social-security-number" v-model="formData.leaseCSocialSecurityNumber">
                                            <label for="yesSocialSecurityNumber">Yes</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" id="noSocialSecurityNumber" value="No" name="radio-group-social-security-number" v-model="formData.leaseCSocialSecurityNumber">
                                            <label for="noSocialSecurityNumber">No</label>
                                        </div> 
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Can a social worker or police officer verify the identity of the applicant? <span class="errorAasterisk">*</span></label>
                                    <div class="radioWrapper d-flex">
                                        <div class="radio">
                                            <input type="radio" id="yesVerifyIdentityApplicant" value="Yes" name="radio-group-credit-check-1" v-model="formData.leaseCVerifyIdentityApplicant">
                                            <label for="yesVerifyIdentityApplicant">Yes</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" id="noVerifyIdentityApplicant" value="No" name="radio-group-credit-check-1" v-model="formData.leaseCVerifyIdentityApplicant">
                                            <label for="noVerifyIdentityApplicant">No</label>
                                        </div> 
                                        <div class="radio">
                                            <input type="radio" id="naVerifyIdentityApplicant" value="N/A" name="radio-group-credit-check-1" v-model="formData.leaseCVerifyIdentityApplicant">
                                            <label for="naVerifyIdentityApplicant">N/A</label>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <label>Charges Pending <span class="errorAasterisk">*</span></label>
                                    <div class="radioWrapper d-flex">
                                        <div class="radio">
                                            <input type="radio" id="yesChargesPending" value="Yes" name="radio-group-charges-pending" v-model="formData.leaseCChargesPending">
                                            <label for="yesChargesPending">Yes</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" id="noChargesPending" value="No" name="radio-group-charges-pending" v-model="formData.leaseCChargesPending">
                                            <label for="noChargesPending">No</label>
                                        </div> 
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label>Previous Felony <span class="errorAasterisk">*</span></label>
                                    <div class="radioWrapper d-flex">
                                        <div class="radio">
                                            <input type="radio" id="yesPreviousFelony" value="Yes" name="radio-group-previous-felony" v-model="formData.leaseCPreviousFelony">
                                            <label for="yesPreviousFelony">Yes</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" id="noPreviousFelony" value="No" name="radio-group-previous-felony" v-model="formData.leaseCPreviousFelony">
                                            <label for="noPreviousFelony">No</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="row">
                                <div class="col-md-6">
                                    <validation-provider :rules="{ required: true,regex:/^[0-9]+$|^[0-9]+[.]?[0-9]+[0-9]*$/}">
                                        <b-form-group
                                            class="userNameFormGroup"
                                            label-for="input-1"
                                            slot-scope="{ failedRules }"
                                        >
                                            <template #label>
                                                Back Taxes Due ($) <span class="errorAasterisk">*</span>
                                            </template>
                                            <b-form-input
                                            class="InputFormClass"
                                            v-model="formData.leaseCBackTaxesDue"
                                            type="text"
                                            placeholder="Enter back taxes due"
                                            autocomplete="off"
                                            ></b-form-input>
                                            <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                            <small class="text-danger error" v-if="failedRules.regex">Please provide valid value.</small>
                                        </b-form-group>
                                    </validation-provider>
                                </div>
                                <div class="col-md-6">
                                    <validation-provider :rules="{ required: true,regex:/^[0-9]+$|^[0-9]+[.]?[0-9]+[0-9]*$/}">
                                        <b-form-group
                                            class="userNameFormGroup"
                                            label-for="input-1"
                                            slot-scope="{ failedRules }"
                                        >
                                            <template #label>
                                                Est. Mortgage(s) balance ($) <span class="errorAasterisk">*</span>
                                            </template>
                                            <b-form-input
                                            class="InputFormClass"
                                            v-model="formData.leaseCEstMortgageBalance"
                                            type="text"
                                            placeholder="Enter est. mortgage(s) balance"
                                            autocomplete="off"
                                            ></b-form-input>
                                            <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                            <small class="text-danger error" v-if="failedRules.regex">Please provide valid value.</small>
                                        </b-form-group>
                                    </validation-provider>
                                </div>
                            </div> -->
                            <!-- <div class="row">
                                <div class="col-md-6 addiFeesCol">
                                    <validation-provider :rules="{regex:/^[0-9]+$|^[0-9]+[.]?[0-9]+[0-9]*$/}">
                                        <b-form-group
                                            class="userNameFormGroup"
                                            label-for="additionalfees"
                                            slot-scope="{ failedRules }"
                                        >
                                            <template #label>
                                                Additional fees to be paid by the buyer ($)
                                            </template>
                                            <b-form-input
                                            class="InputFormClass"
                                            v-model="formData.leaseCAdditionalFeesToBePaidByTheBuyer"
                                            type="text"
                                            autocomplete="off"
                                            placeholder="Enter additional fees to be paid by the buyer"
                                            ></b-form-input>
                                            <small class="text-danger error" v-if="failedRules.regex">Please provide valid value.</small>
                                        </b-form-group>
                                    </validation-provider>
                                </div>
                            </div> -->
                            
                            <!-- <div class="row radioBox">
                                <div class="col-md-12">
                                    <label class="radioBoxLabel">Does the note require a cash buyer? <span class="errorAasterisk">*</span></label>
                                    <div class="radioWrapper d-flex">
                                        <div class="radio">
                                            <input type="radio" id="yesday" :value="true" name="radio-group-day" v-model="formData.leaseCNoteRequireACashBuyer">
                                            <label for="yesday">Yes</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" id="noday" :value="false" name="radio-group-day" v-model="formData.leaseCNoteRequireACashBuyer">
                                            <label for="noday">No</label>
                                        </div> 
                                    </div>
                                </div>
                            </div> -->
                            
                            <!-- <div class="row radioBox daysCount">
                                <div class="col-md-12">
                                    <label class="radioBoxLabel">Will the seller offer seller financing (attorney to draft)? <span class="errorAasterisk">*</span></label>
                                    <div class="radioWrapper d-flex">
                                        <div class="radio">
                                            <input type="radio" id="yesdaydraft" :value="true" name="radio-group-draft-1" v-model="formData.leaseCSellerOfferSellerFinancing">
                                            <label for="yesdaydraft">Yes</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" id="nodaydraft" :value="false" name="radio-group-draft-1" v-model="formData.leaseCSellerOfferSellerFinancing">
                                            <label for="nodaydraft">No</label>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            
                            <!-- <div class="row radioBox daysCount">
                                <div class="col-md-12">
                                    <label class="radioBoxLabel">When does the seller want to convey the financial instrument? <span class="errorAasterisk">*</span></label>
                                    <div class="radioWrapper d-flex">
                                        <div class="radio">
                                            <input type="radio" id="7days" value="7-14 days" name="radio-group-property-1" v-model="formData.leaseCSellerWantToConveyTheFinanciallnstrument">
                                            <label for="7days">7-14 days</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" id="14days" value="14-30 days" name="radio-group-property-1" v-model="formData.leaseCSellerWantToConveyTheFinanciallnstrument">
                                            <label for="14days">14-30 days</label>
                                        </div> 
                                        <div class="radio">
                                            <input type="radio" id="30days" value="30-60 days" name="radio-group-property-1" v-model="formData.leaseCSellerWantToConveyTheFinanciallnstrument">
                                            <label for="30days">30-60 days</label>
                                        </div> 
                                        <div class="radio">
                                            <input type="radio" id="60days" value="60 days+" name="radio-group-property-1" v-model="formData.leaseCSellerWantToConveyTheFinanciallnstrument">
                                            <label for="60days">60 days+</label>
                                        </div> 
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="row additionalDetailTextArea">
                                <div class="col-md-12">
                                    <validation-provider :rules="{ required: true}">
                                        <b-form-group
                                            class="userNameFormGroup textAreaClassGroup"
                                            label-for="input-1"
                                            slot-scope="{ failedRules }"
                                        >
                                        <template #label>
                                        Additional Details <span class="errorAasterisk">*</span>
                                        <span class="textCount">{{formData.leaseCAdditionalDetails ? formData.leaseCAdditionalDetails.length :0 }}/600</span>
                                    </template>
                                           
                                            <b-form-textarea
                                                class="InputFormClass textAreaClass"
                                                v-model="formData.leaseCAdditionalDetails"
                                                type="textarea"
                                                placeholder="Any additional information for the buyer to consider"
                                                maxLength="600"
                                            ></b-form-textarea>
                                            <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                        </b-form-group>
                                    </validation-provider>
                                </div>
                            </div>
                            <div class="checkListWrapper rehabType">
                                <label>Rehab Type <span class="errorAasterisk">*</span> <span class="conditionApply">(Check all that apply)</span></label>
                                <div class="row checkBoxRow">
                                <div class="col-md-12">
                                <form class="d-flex">
                                        <div class="form-group-inputRequired">
                                    <validation-provider :rules="{ required: true}">
                                            <div class="d-flex" slot-scope="{ failedRules }" style="flex-wrap:wrap;">
                                                <div v-for="(rowValueRehab,index12) in dataRehab" :key="index12" class="form-group-chekbox common-multiple-checkbox checkboxValue">
                                                    <input  type="checkbox" :id="rowValueRehab.id" :value="rowValueRehab.label" @change="handleChangeRehab" v-model="formData.leaseCRehabType" />
                                                    <label :for="rowValueRehab.id" class="checkBoxLabel">{{ rowValueRehab.label }}</label>
                                                </div>
                                                <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                            </div>
                                    </validation-provider>
                                        </div>
                            </form>
                            </div>
                                </div>
                            </div> -->
                            <!-- <div class="row additionalDetailTextArea majorRepairsRow">
                                <div class="col-md-12">
                                    <validation-provider :rules="{ required: true}">
                                        <b-form-group
                                            class="userNameFormGroup textAreaClassGroup"
                                            label-for="input-1"
                                            slot-scope="{ failedRules }"
                                        >
                                        <template #label>
                                            List Any Major Repairs Needed <span class="errorAasterisk">*</span>
                                            <span class="textCount">{{formData.leaseCAnyMajorRepairsNeeded ? formData.leaseCAnyMajorRepairsNeeded.length : 0}}/600</span>
                                    </template>
                                       
                                            <div class="textAreaCount">
                                            <span class="textCount"></span>
                                            </div> 
                                            <b-form-textarea
                                                class="InputFormClass textAreaClass"
                                                v-model="formData.leaseCAnyMajorRepairsNeeded"
                                                type="textarea"
                                                placeholder="Enter major repairs needed"
                                                maxLength="600"
                                            ></b-form-textarea>
                                            <small class="text-danger error" v-if="failedRules.required">The field is required.</small>
                                        </b-form-group>
                                    </validation-provider>
                                </div>
                            </div>
                            <div class="row radioBox currentOccupacyRow">
                                <div class="col-md-12">
                                    <label class="radioBoxLabel">Current Occupancy <span class="errorAasterisk">*</span></label>
                                    <div class="d-flex cuuentOccuRadioWrapper">
                                        <div class="radioWrapper ownerOccupied">
                                        <div class="radio">
                                            <input type="radio" id="OwnerOccupied" value="Owner Occupied" name="radio-group-property-2" v-model="formData.leaseCCurrentOccupancy">
                                            <label for="OwnerOccupied">Owner Occupied</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" id="Vacant" value="Vacant" name="radio-group-property-2" v-model="formData.leaseCCurrentOccupancy">
                                            <label for="Vacant">Vacant</label>
                                        </div> 
                                        </div>
                                        <div class="radioWrapper tenantOccupied">
                                        <div class="radio">
                                            <input type="radio" id="VacationRental" value="Vacation Rental" name="radio-group-property-2" v-model="formData.leaseCCurrentOccupancy">
                                            <label for="VacationRental">Vacation Rental</label>
                                        </div> 
                                       
                                        <div class="radio">
                                            <input type="radio" id="TenantOccupied" value="Tenant Occupied" name="radio-group-property-2" v-model="formData.leaseCCurrentOccupancy">
                                            <label for="TenantOccupied">Tenant Occupied</label>
                                        </div> 
                                    </div>
                                    <div class="radioWrapper unknownWrapper">
                                        <div class="radio">
                                            <input type="radio" id="Unknown25" value="Unknown" name="radio-group-property-2" v-model="formData.leaseCCurrentOccupancy">
                                            <label for="Unknown25">Unknown</label>
                                        </div>                   
                                        </div>
                                </div>
                            </div>
                            </div> -->
                            </div>
                            <!-- Landing Info Form End -->
                            <!-- Asking Price Start -->
                            <div class="askingPriceViewForm">
                            <h5 class="forInternUseHeading">VACANCIES</h5>
                            <div class="row">
                                    <div class="col-md-6">
                                        <label>Intake Method <span class="errorAasterisk">*</span></label>
                                        <div class="radioWrapper d-flex">
                                            <div class="radio radio-intake-merhod">
                                                <input type="radio" id="yesIntakeMethod" value="First Come First Serve" name="radio-group-intake-method" v-model="formData.leaseDIntakeMethod">
                                                <label for="yesIntakeMethod">First Come First Serve</label>
                                            </div>
                                            <div class="radio radio-intake-merhod">
                                                <input type="radio" id="noIntakeMethod" value="We Will Contact You" name="radio-group-intake-method" v-model="formData.leaseDIntakeMethod">
                                                <label for="noIntakeMethod">We Will Contact You</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 selectWrapper">
                                        <label>Capacity <span class="errorAasterisk">*</span></label>
                                        <div class="customerStyle">
                                            <validation-provider :rules="{ required: true}">
                                                <div slot-scope="{ failedRules }">
                                                    <select class="selectCustomer" v-model="formData.leaseDCapacity" >
                                                        <option value="" hidden disabled>Select</option>
                                                        <option :value="i" v-for="(i,ind) in 100" :key='ind'>{{i}}</option>
                                                    </select>
                                                    <small class="text-danger" v-if="failedRules.required">The field is required.</small>
                                                </div>
                                            </validation-provider>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 selectWrapper">
                                        <label>Number of Vacancies <span class="errorAasterisk">*</span></label>
                                        <div class="customerStyle">
                                            <validation-provider :rules="{ required: true}">
                                                <div slot-scope="{ failedRules }">
                                                    <select class="selectCustomer" v-model="formData.leaseDNumberOfVacancies" >
                                                        <option value="" hidden disabled>Select</option>
                                                        <option :value="i" v-for="(i,ind) in 100" :key='ind'>{{i}}</option>
                                                    </select>
                                                    <small class="text-danger" v-if="failedRules.required">The field is required.</small>
                                                </div>
                                            </validation-provider>
                                        </div>
                                    </div>
                                    <div class="col-md-6 selectWrapper">
                                        <validation-provider :rules="{ required: true}">
                                            <b-form-group
                                                class="userNameFormGroup"
                                                label-for="input-1"
                                                slot-scope="{ failedRules }"
                                            >
                                                <template #label>
                                                    Last Updated  <span class="errorAasterisk">*</span>
                                                </template>
                                                <DatePicker ref='originDate' class="dateChanges" v-model="formData.leaseDOriginationDate" :masks="masks" :mode="'dateTime'" :popover="{ visibility: 'click' }">
                                                    <template v-slot="{ inputValue, inputEvents,hidePopover }">
                                                        <!-- <button @click="togglePopover()">
                                                        click
                                                        </button> -->
                                                        <input
                                                        maxlength="10"
                                                        @keypress.enter="hidePopover()"
                                                        class="InputFormClass dateValuechanges"
                                                        style="cursor:pointer;"
                                                        :placeholder="masks.input"
                                                        :value="inputValue"
                                                        v-on="inputEvents"
                                                        />
                                                    </template>
                                                </DatePicker>
                                                <!-- <div class="dateChanges">
                                                    <div class="dateValue">
                                                        {{formData.leaseDOriginationDate ? formData.leaseDOriginationDate : 'yyyy-mm-dd'}}
                                                    </div>
                                                    <input
                                                    class="InputFormClass dateValuechanges"
                                                    v-model="formData.leaseDOriginationDate"
                                                    type="date"
                                                    @copy.prevent
                                                    @paste.prevent
                                                    ondragover="return false"
                                                    onkeydown="return false"
                                                    />
                                                </div> -->
                                                <small class="text-danger" v-if="failedRules.required">The field is required.</small>
                                            </b-form-group>
                                        </validation-provider>
                                    </div>
                                </div>
                            </div>
                            <!-- Income Potential End -->
                            <!-- photo video docment start -->
                            <div class="photoVideoDocViewForm">
                            <div class="uploadPhotoswrapper">
                                <h5 class="forInternUseHeading">PHOTOS / VIDEO / DOCUMENTATION </h5>
                            <label>Upload up to 20 photos</label>
                            <div class="uploadPhotos">
                                <div class="uploadImages uploadInputPhotoDiv">    
                                    <div class="file-input">
                                        <input
                                            type="file"
                                            name="file-input"
                                            id="UploadedFile"
                                            class="file-input__input"
                                            ref="attach_files"
                                            hidden
                                            multiple
                                            accept="image/*"
                                            @change.prevent="listImageUploads"
                                        />
                                        <label class="file-input__label" for="UploadedFile">
                                        <img src="../../../../assets/images/svg/uploadPlus.svg" />
                                            </label
                                        >
                                    </div>
                                </div>
                                <div class="uploadImages" v-for="(images,index) in (imagesArray.length >= 4 ? imagesArray.slice(0,4) : imagesArray)" :key="index">
                                    <!-- <div class="content"> -->
                                    <a style="cursor: default">
                                        <!-- <div class="content-overlay"></div> -->
                                            <img class="content-image" :src="images.fileUrl">
                                        <!-- <div class="content-details fadeIn-bottom"> -->
                                            <p class="content-text" @click="imageModel" :class="{'adddd':index === 3}" v-if="index === 3 && imagesArray.length >= 4">+{{imagesArray.length ? imagesArray.length-4 : 0}} More Photos</p>
                                            <img class="content-text-value" style="cursor: pointer;" @click="openModel('image'),removeImageIndex = index" :class="{'removeDeleteIcon':index === 3}" src="../../../../assets/images/closeIcon.png">
                                        <!-- </div> -->
                                    </a>
                                    <!-- </div>  -->
                                  </div>
                            </div>
                            <span class="imageErrorNotification">( One photo is required, each photo should be less than a 2 MB. )</span>
                        </div>
                        
                        <div class="uploadPhotoswrapper uploadVideoWrapper">
                            <label>Upload up to one 60 second video </label>
                        <div class="uploadPhotos">
                            <div class="uploadImages uploadInputPhotoDiv">    
                                <div class="file-input">
                                    <input
                                        type="file"
                                        name="file-input"
                                        id="uploadVideo"
                                        class="file-input__input"
                                        accept=".mp4,.mov,.WebM,.mkv,.m4v"
                                        @change.prevent="listVideoUploads"
                                    />
                                    <label class="file-input__label" for="uploadVideo">
                                    <img src="../../../../assets/images/svg/uploadPlus.svg" />
                                        </label
                                    >
                            </div>
                            </div>
                            <div class="uploadImages" v-if="videoUrl !== ''">
                                  <div class="videoHoverDesign">
                            <a href="#" target="_blank">
                                <div class="video-overlay"></div>
                                <video @click.prevent="(e) => {e.preventDefault(); playVideo('video')}" :key="videoUrl" width="302" height="170" controls :ref="'video'">
                                    <source :src="videoUrl" type="video/mp4">
                                </video>
                                <div class="video-details" v-if="showVideoPlayBtn">
                                    <span class="video-hover-image"><img src="@/assets/images/playIconData.png" @click.prevent="playVideo('video')"/></span>
                                </div>
                            </a>
                            <div class="image_value">
                                <img class="image_hover_value" @click="openModel('video')" src="@/assets/images/closeIcon.png" />
                            </div>
                            </div>
                            </div>
                            <!-- <img @click="openModel('video')" src="../../../../assets/images/closeIcon.png" /> -->
                        </div>
                        <span class="imageErrorNotification">(  Video should be less than a 5 MB. )</span>
                        </div>
                        <div class="uploadPhotoswrapper documnetUploadWrapper">
                        <label>Upload up to Several pages in one pdf format</label>
                        <div class="uploadFileWithErrorWrapper d-flex">
                        <div class="documentUploadWrapper">
                            <div>
                            <input type="text" placeholder="Upload document " readonly>
                            </div>  
                            <div class="file-input documnetUplopad">
                            <input
                                type="file"
                                name="file-input"
                                id="uploadFileArray"
                                class="file-input-upload"
                                ref="uploadPdfFile"
                                multiple
                                accept="application/pdf"
                                @change.prevent="listFileUploads"
                            />
                            <label class="file-input-documentlabel" for="uploadFileArray">
                                <img src="../../../../assets/images/svg/documentUpload.svg" />
                                <span>Upload</span></label
                            >
                            </div>
                            
                        </div>
                        <div class="errorMessageVideo">
                                <span class="imageErrorNotification">( Document should be less than a 5 MB. )</span>
                            </div>
                            </div>  
                         <div class="fileUploadDetail" v-if="attachemntArray && attachemntArray.length"> 
                            <div class="fileUploadDetailvalue" v-for="(files,index) in attachemntArray" :key="index">
                                <span class="fileUploadColor">
                                    {{files.fileName}}  
                                </span>
                                <span>
                                    <img class= "file_value" style="cursor:pointer;" @click="openModel('doc'),removeDocIndex = index" src="../../../../assets/images/closeIcon.png" />
                                </span>
                                <!-- {{files.fileName}}
                                <img style="cursor:pointer;" @click="openModel('doc'),removeDocIndex = index" src="../../../../assets/images/closeIcon.png" /> -->
                            </div>
                    </div>
                    </div>
                            </div>
                            <!-- photo video docment End -->
                        </b-form>
                    </validation-observer>
                </div>
                <div class="propertyAddressBtn">
                    <button class="btn btn-primary editBtn" :disabled="isSpinner" @click="updateButtonCall">Save</button>
                </div> 
            </div>
        </div>
        <b-modal
        id="modal-images"
        centered
        hide-header
      >
      <h3>Uploaded Images</h3>
      <div class="images-wrapper">
      <div class="imageAdd" v-for="(images,index) in imagesArray" :key="index">
         <img class="content-image" :src="images.fileUrl">
         <img class="imagehover" @click="openModel('image'),removeImageIndex = index" src="../../../../assets/images/closeIcon.png">
      </div>
      </div>
      </b-modal>
      <b-modal
                id="modal-removeDoc"
                ok-title="Confirm"
                cancel-title="Cancel"
                centered
                @ok="removeDoc"
                @cancel="cancelStatusDoc"
                :no-close-on-backdrop="true"
                hide-header
            >
            <h4 style="text-align:center;">Are you sure you want to delete the file?</h4>
            </b-modal>
            <b-modal
                id="modal-removeVideo"
                ok-title="Confirm"
                cancel-title="Cancel"
                centered
                @ok="removeVideo"
                @cancel="cancelStatusVideo"
                :no-close-on-backdrop="true"
                hide-header
            >
            <h4 style="text-align:center;">Are you sure you want to delete the video?</h4>
            </b-modal>
            <b-modal
                id="modal-removeImage"
                ok-title="Confirm"
                cancel-title="Cancel"
                centered
                @ok="removeImageFunction"
                @cancel="cancelStatus"
                :no-close-on-backdrop="true"
                hide-header
            >
            <h4 style="text-align:center;">Are you sure you want to delete the image?</h4>
            </b-modal>
            <b-modal
                id="modal-alredyAddress"
                centered
                hide-footer
            >
                <h4>
                    This exact property address is currently Active or Private on shelter-registry.com. 
                    If you are the legal owner of this property, contract or financial instrument and do not have an agent working on the property on your behalf, please 
                    <router-link to="/contact-us" style="color:#17D1C6;text-decoration:none">Contact Us.</router-link>
                    If you have previously listed this property, contract or financial instrument, please Login and update your listing dashboard to change the listing status.
                </h4>
            </b-modal>
    </div>
</template>

<script>
import moment from "moment";
import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollections"
const db = firebase.firestore();
import { country,state } from '@/utils/countryStateApi.js'
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import * as updateFirebase from '@/utils/FirebaseQueries/updateQueries/updateQueries.js';
import {uploadImageAsPromise,removeImage} from '@/utils/FirebaseQueries/firebaseFileUploadFun.js'
import * as updateTypesense from '@/utils/TypeSenseQueries/updateQueries.js';
import {checkAddress} from  "@/utils/commonMethods.js";
import { mapActions,mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import MultiSelect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
 export default {
    name: "AssignContractListing",
    components: {
        ValidationProvider, 
        ValidationObserver,
        DatePicker,
        VueEditor,
        MultiSelect
    },
    data() {
        return {
              showVideoPlayBtn: true,
            formData: {
                listingType:'mental_or_detox_facility',
                updatedAt: new Date(),
                // leaseAIsPropertyHasMorgageNoteOrLeaseOption:true,
                // leaseALanderNameOnTitle:'',
                leaseAShelterName:'',
                leaseALanderNameOnTitle:'',
                leaseALanderBestPhoneNumber:'',
                // leaseAIsMortgageNoteOwnedByCompanyStateWhereCompanyFormedIn:false,
                // leaseAMortgageNoteOwnedByCompanyStateWhereCompanyFormedIn:'',
                // leaseAIsMortgageNoteOwnedByCompanyOperatorsOfTheCompany:false,
                // leaseAMortgageNoteOwnedByCompanyOperatorsOfTheCompany:'',
                // leaseAAreYouABrokerWithSignedAgreement:true,
                leaseAStreetNumber:'',
                leaseAStreetName:'',
                leaseBMortgageNoteOrALeaseOptionForSale:'mortgage note',
                leaseBCountry:'United States',
                leaseBState:'',
                leaseBCity:'',
                leaseBZipCode:'',
                leaseBLongitude:'',
                leaseBLatitude:'',
                leaseBDescription:'',
                leaseBShowingInstructions:'',
                leaseBSelectPropertyType:'',
                leaseBHouseingType:"Open Concept Shelter",
                leaseBBedroomCount:'',
                leaseBBathroomCount:'',
                leaseBGarageCarCount:'',
                leaseBIndoorLivingSquareFoot:'',
                leaseBLotSize:'',
                leaseBYearBuilt:'',
                leaseBBathroomType:'Shared',
                leaseBUtilitiesCost:'Yes',
                leaseBEntryFee:'',
                leaseBPaymentMethod:[],
                leaseBAge:[],
                leaseBGender:[],
                leaseBLengthOfStay:[],
                leaseBSelectMonthlyPricePerPerson:[],

                leaseCLanguageSpokenAtShelter:'',
                leaseCSecuritySystem:'Yes',
                leaseCTransportationProvided:'Yes',
                leaseCBedFurnitureProvided:'Yes',
                leaseCFoodProvided:'Yes',
                leaseCAppliancesProvided:'Yes',
                leaseCInternetProvided:'Yes',
                leaseCHeating:'Yes',
                leaseCAirConditioning:'Yes',
                leaseCHouseManager:'Yes',
                leaseCPropertyManager:'Yes',
                leaseCSupportServiceCommunity:'Yes',
                leaseCEntryLevelWork:'Yes',
                leaseCPublicTrasportation:'Yes',
                leaseCValidIdNeeded:'Yes',
                leaseCBackgroundCheck:'Yes',
                leaseCCreditCheck:'Yes',
                leaseCSocialSecurityNumber:'Yes',
                leaseCVerifyIdentityApplicant:'Yes',
                leaseCChargesPending:'Yes',
                leaseCPreviousFelony:'Yes',
                leaseDOriginationDate:null,
                leaseDNumberOfVacancies:'',
                leaseDIntakeMethod:'',
                leaseDCapacity:'',
                // leaseBRoof:'',
                // leaseBHVAC:'',
                // leaseBFlooring:'',
                // leaseBWindows:'',
                // leaseBPlumbing:'',
                // leaseBElectrical:'',
                // leaseBParkingFeatures:'',
                // leaseBFoundation:'',
                // leaseBSewerInformation:'',
                // leaseBWaterInformation:'',
                // leaseBHighlight1:'',
                // leaseBHighlight2:'',
                // leaseBOtherComments:'',
                // leaseBIsPropertyInLivableConfition:'Is livable today',
                // leaseCIsAnnualHOAAmount:false,
                // leaseCAnnualHOAAmount:null,
                // leaseCAnnualTaxAmount:null,
                // leaseCBackTaxesDue:null,
                // leaseCEstMortgageBalance:null,
                // leaseCAdditionalFeesToBePaidByTheBuyer:null,
                // leaseCNoteRequireACashBuyer: true,
                // leaseCSellerOfferSellerFinancing:true,
                // leaseCSellerWantToConveyTheFinanciallnstrument:'7-14 days',
                // leaseCAdditionalDetails:'',
                // leaseCRehabType:[],
                // leaseCAnyMajorRepairsNeeded:'',
                // leaseCCurrentOccupancy:'Owner Occupied',
                // leaseDNoteType:'Performing',
                // leaseDNotePosition:'1st',
                // leaseDAskingPrice:'',
                // leaseDUPB:'',
                leaseDOriginationDate:null,
                // leaseDMaturityDate:null,
                // leaseDLastPaymentDate:'',
                // leaseDAccruedBalance:'',
                // leaseDCurrentIntrestRate:'',
                // leaseDMonthlyPaymentDue:'',
                // leaseDCurrentLoanTerms:'',
                leaseFUploadSeveralPages:[],
                leaseFUploadVideo:'',
                leaseFUploadPhotos:[],
                leaseAUnitorLot:'',
                leaseCIsleaseAUnitorLot: false

            },
            dataRehab:[
                { id: 'No Rehab Needed', label: 'No Rehab' },
                { id: 'Cosmetic', label: 'Cosmetic' },
                { id: 'Moderate', label: 'Moderate' },
                { id: 'Heavy', label: 'Heavy' },
                { id: 'Full Tear Down', label: 'Full Tear Down' },
                { id: 'Fire Damage', label: 'Fire Damage' },
                { id: 'Water Damage', label: 'Water Damage' },
                { id: 'Mold', label: 'Mold' },
                { id: 'Cracked Slab', label: 'Cracked Slab' },
                { id: 'Raw Land', label: 'Raw Land' },
                { id: 'Build Ready', label: 'Build Ready' },
                { id: 'Unknown', label: 'Unknown' },
            ],
            
            // dataRehabTypeRental:[
            //     { id: 'Long Term Rental', label: 'Long Term Rental' },
            //     { id: 'Short Term Rental', label: 'Short Term Rental' },
            //     { id: 'Vacation Rental', label: 'Vacation Rental' },
            //     { id: 'Student Housing', label: 'Student Housing' },
            //     { id: 'Assisted Living', label: 'Assisted Living' },
            //     { id: 'Subject 8 Approved', label: 'Subject 8 Approved' },
            //     { id: 'Subject 8 Available', label: 'Subject 8 Available' },
            //     { id: 'Unknown45', label: 'Unknown' },
            // ],
            video:'',
            TypeRental:[],
            Rehab:[],
            imagesArray:[],
            attachemntArray:[],
            countryList: [],
            stateList:state,
            commonArrayFOr:[],
            localArray:[],
            localFileArray:[],
            filesArray:[],
            isSpinner:false,
            videoUrl:'',
            stateOfUseAndCanada : [
                'Alabama','Alaska','American Samoa','Arizona','Arkansas','California','Colorado','Connecticut',
                'Delaware','District of Columbia','Federated States of Micronesia','Florida','Georgia','Guam','Hawaii',
                'Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Marshall Islands','Maryland','Massachusetts',
                'Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey',
                'New Mexico','New York','North Carolina','North Dakota','Northern Mariana Islands','Ohio','Oklahoma','Oregon',
                'Palau','Pennsylvania','Puerto Rico','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah',
                'Vermont','Virgin Island','Virginia','Washington','West Virginia','Wisconsin','Wyoming','Alberta', 'British Columbia',
                'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Northwest Territories', 'Nova Scotia', 'Nunavut',
                'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan', 'Yukon Territory'
            ],
            removeDocIndex:'',
            removeImageIndex:'',
            removeItemList:[],
            // propertyTypeArray:[
            //     'Single Family',
            //     'Condominium',
            //     '2 Unit',
            //     '3 Unit',
            //     '4 Unit',
            //     '5 Units or more',
            //     'Steel Barndominium',
            //     'Steel Pole Barn Home',
            //     'Lumber Bardominium',
            //     'Lumber Pole Barn Home',
            //     'Shipping Container Home',
            //     'Unique Home',
            //     'Residential Land',
            //     'Other',
            // ],
            propertyTypeArray:[
                'Medical Psychiatric Facility',
                'Medical Detox Facility',
                'Nursing Home',
                'Public Shelter',
                'Private Shelter',
                'Military Veteran',
                'Foster Care Home',
                'Aged Out Foster Care',
                'Halfway Home',
                'College Housing',
                'Victims of Domestic Violence',
                'Victims of Sex Trafficking',
                'Victims of Labor Trafficking',
                'Immigrant or Refugee Status',
                'Sober Living < 6 months',
                'Sober Living > 6 months',
                'Medically Assisted Treatment',
                'Violent Offenders Permitted',
                'Sexual Offenders Permitted',
                'Housing as a Condition of Parole',
                'Children Permitted',
                'Pets Permitted',
                'Smoking Permitted',
                'Wheelchair Accessible',
                'Housing for Pregnant Women',
                'Co-Ed Housemates',
                'NARR level 1',
                'NARR level 2',
                'NARR level 3',
                'NARR level 4',
                'Oxford House',
                'Veterans Housing'
            ],
            monthlyPerPersonPrice:[
                'Free',
                '$0 to $500',
                '$501 to $700',
                '$701 to $1000',
                '$1001 to $1200',
                '$1201 to $1500',
                '$1500 to $2000',
                '$2000 or more'
            ],
            optionsEntryFee:[
                'Free',
                '$0 to $100',
                '$101 to $200',
                '$201 to $500',
                '$501 to $1000',
                '$1001 to $1200',
                '$1201 to $1500',
                '$1500 to $2000',
                '$2000 or more'
            ],
            optionsPaymentMethods:[
                'Debit or Credit Card',
                'Check',
                'Cash App',
                'Health Insurance',
                'Drug Court',
                'Grant Funding',
                'Co-Signer',
                'Section 8',
                'Other Government Funding',
                'Student Loan Funding',
                'Social Security Income',
                'Social Security Disability Income',
                'AHCCCS American Indian',
                'United Way',
                'Veterans or Military'
            ],
            optionsAge:[
                '60 years and older',
                '40 years and older',
                '18 years and older',
                '17 years and younger',
                '10 years and younger'
            ],
            optionsGender:[
                'Male',
                'Female',
                'Male + Child',
                'Female + Child',
                'LGBTQIA'
            ],
            optionsLengthOfStay: [
                '1 night',
                '2 nights',
                '3 to 5 nights',
                '5 to 7 nights',
                '7 to 14 nights',
                'Up to One Month',
                'Up to 3 Months',
                'Up to 6 Months',
                'Up to 1 Year',
                'Up to 1.5 Years',
                'Up to 2 years',
                'Over 2 years'
            ],
            //DATE PICKER CONFIG
            date: new Date(),
            hideValOfStartDate: true,
            masks: {
                input: 'MM/DD/YYYY',
            },
            maxDate:'',
            minDate:'',
            customToolbar: [
                ["bold", "italic", "underline","link"],
            ],
            descriptionLength:0,
        };
    },
    created(){
        let date = new Date().toISOString().split('T')[0]
        let datasss = date.split('-')
        console.log(date,'HSgfggdfHSHgfgdSgfdgHSdfgHdggdfgSHSdfHSSHHSSYIUSISSISYGSUTYSHSOGIUSGIGSI',datasss)
        this.maxDate = new Date(Number(datasss[0]) + 15,Number(datasss[1]) - 1,datasss[2])
        this.minDate = new Date(Number(datasss[0]) - 15,Number(datasss[1]) - 1,datasss[2])
        console.log(this.minDate,this.maxDate,'MIN MAX DATE')
        console.log('FUNCTION MORTGAGER')
        let definedVal = [];
        this.isSpinner = true;
        country.forEach((ele)=>{
            if(ele.name === 'United States'){
                definedVal.push(ele)
            }
        })
        this.countryList = definedVal;
        if(this.$route.params.id){
            db.collection(dbCollections.PROPERTY).doc(this.$route.params.id).get().then((property)=>{
                console.log(property,'propertypropertypropertypropertyproperty')
                this.videoUrl = property.data().leaseFUploadVideo !== '' ? property.data().leaseFUploadVideo : '';
                if(property.data().leaseFUploadSeveralPages && property.data().leaseFUploadSeveralPages.length != 0){
                    property.data().leaseFUploadSeveralPages.forEach((ele)=>{
                        let fName = this.documentName(ele)
                        let arr = fName.split('_');
                        arr.splice(0,1);
                        let fileName = arr.join("_");
                        this.localFileArray.push(ele);
                        this.attachemntArray.push({fileName:fileName,type:'local',firebaseUrl:ele})
                        // this.localFileArray.push(ele);
                        // this.attachemntArray.push({fileName:fName})
                    })
                }
                if(property.data().leaseFUploadPhotos && property.data().leaseFUploadPhotos.length != 0){
                    property.data().leaseFUploadPhotos.forEach((ele)=>{
                        let fName = this.documentName(ele)
                        this.localArray.push(ele);
                        this.imagesArray.push({'imgURL':fName,'fileUrl':ele,type:'local'})
                    })
                }
                console.log(property.data(),'property');
                this.formData = {...this.formData,...property.data()}
                this.formData.listingType = property.data().listingType
                // this.countryList.forEach((ele)=>{
                //     if(ele.name == property.data().leaseBCountry){
                //         console.log(ele,'eleeleele')
                //         this.formData = {...this.formData,leaseBCountry:ele}
                //         this.stateList = State.getStatesOfCountry(this.formData.leaseBCountry.isoCode)
                //         console.log(this.formData,'this.formData')
                //     }
                // })
                if(this.formData.leaseCRehabType && this.formData.leaseCRehabType.length !== 0){
                    this.formData.leaseCRehabType.forEach((value)=>{
                        this.Rehab.push(value);
                    })
                }
                if(this.formData.leaseDOriginationDate && this.formData.leaseDOriginationDate !== null){
                    this.formData.leaseDOriginationDate = this.changeDateFormat(this.formData.leaseDOriginationDate,'MM/DD/YYYY, h:mm:ss')
                }
                // if(this.formData.leaseDMaturityDate && this.formData.leaseDMaturityDate !== null){
                //     this.formData.leaseDMaturityDate = this.changeDateFormat(this.formData.leaseDMaturityDate,'MM/DD/YYYY')
                // }
                // if(this.formData.leaseDLastPaymentDate && this.formData.leaseDLastPaymentDate !== null){
                //     this.formData.leaseDLastPaymentDate = this.changeDateFormat(this.formData.leaseDLastPaymentDate,'MM/DD/YYYY')
                // }
                this.$forceUpdate()
                this.isSpinner = false;
            }).catch((error)=>{
                console.error(error)
                this.isSpinner = false;
            })
        }
        this.allOfferData();
    },
    methods: {
        ...mapActions({
            allOfferData : "offer/allOfferData"
        }),
        callTextChange(){
        var self = this;
        const quill = self.$refs.refComment.quill;
        const len = quill.getLength();
            self.descriptionLength = len-1; 
            if (len >= 1000) {
            quill.deleteText(1000, len);
            }
        },
        handleError() {
            console.log('enter in handle error');
            setTimeout(() => {
                let errors = document.getElementsByClassName("error");

                if(errors && errors.length) {
                    var element = errors[0];
                    var headerOffset = 200;
                    var elementPosition = element.getBoundingClientRect().top;
                    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                    window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth"
                    });

                }
            }, 100)
        },
        playVideo(refVar) {
            if(this.showVideoPlayBtn) {
                this.$nextTick(() => {
                    this.$refs[refVar].play();
                })
            } else {
                this.$nextTick(() => {
                    this.$refs[refVar].pause();
                })
            }
            this.showVideoPlayBtn = !this.showVideoPlayBtn;
        },
        dateCalCloseOpen(val){
            console.log('lastDate,mDate',this.$refs.lastDate,this.$refs.mDate)
            console.log(val,'valvalvalvalvalvalvalvalvalvalval')
            if(val == 'lastDate'){
                this.$refs.mDate.hidePopover()
                this.$refs.originDate.hidePopover()
            }
            if(val == 'maturityDate'){
                this.$refs.lastDate.hidePopover()
                this.$refs.originDate.hidePopover()
            }
            if(val == 'originDate'){
                this.$refs.lastDate.hidePopover()
                this.$refs.mDate.hidePopover()
            }
        },
        openModel(type){
            if(type == 'image'){
                this.$bvModal.show(`modal-removeImage`);
            }else if(type == 'video'){
                this.$bvModal.show(`modal-removeVideo`);
            }else if(type == 'doc'){
                this.$bvModal.show(`modal-removeDoc`);
            }
        },
        cancelStatus(){
            this.removeImageIndex = '';
        },
        removeImageFunction(){
            console.log(this.removeImageIndex,'ind')
            let object = this.imagesArray[this.removeImageIndex]
            if(object.type == 'local'){
                let httpsReference = firebase.storage().refFromURL(object.fileUrl)
                let name = httpsReference.name
                console.log(name)
                // removeImage("realEstate/", name, (res) => {
                //     console.log('DONE',res)
                //     if(res){
                    this.removeItemList.push(name);
                    let findIndex = this.localArray.findIndex((ele)=>{return ele == object.fileUrl})
                    let findIndex2 = this.imagesArray.findIndex((ele)=>{return ele.fileUrl == object.fileUrl})
                    if(findIndex != -1){
                        this.localArray.splice(findIndex,1)
                    }
                    if(findIndex2 != -1){
                        this.imagesArray.splice(findIndex2,1)
                    }
                    this.removeImageIndex = '';
                    this.$toast.success("Image deleted successfully.")
                    // }else{
                    //     console.log('ERROR')
                    // }
                // });
            }else if(object.type == 'uploadlocal'){
                let findIndex = this.commonArrayFOr.findIndex((ele)=>{return (ele.lastModified + '_' +ele.name) == object.name})
                let findIndex2 = this.imagesArray.findIndex((ele)=>{return ele.name == object.name})
                if(findIndex != -1){
                    this.commonArrayFOr.splice(findIndex,1)
                }
                if(findIndex2 != -1){
                    this.imagesArray.splice(findIndex2,1)
                }
                this.removeImageIndex = '';
                this.$toast.success("Image deleted successfully.")
            }
        },
        removeDoc(){
            let object = this.attachemntArray[this.removeDocIndex]
            if(object.type == 'local'){
                let httpsReference = firebase.storage().refFromURL(object.firebaseUrl)
                let name = httpsReference.name
                console.log(name)
                // removeImage("realEstate/", name, (res) => {
                //     console.log('DONE',res)
                //     if(res){
                    this.removeItemList.push(name);
                    let findIndex = this.localFileArray.findIndex((ele)=>{return ele == object.firebaseUrl})
                    let findIndex2 = this.attachemntArray.findIndex((ele)=>{return ele.firebaseUrl == object.firebaseUrl})
                    if(findIndex != -1){
                        this.localFileArray.splice(findIndex,1)
                    }
                    if(findIndex2 != -1){
                        this.attachemntArray.splice(findIndex2,1)
                    }
                    this.$toast.success("PDF deleted successfully.")
                    // }else{
                    //     console.log('ERROR')
                    // }
                // });
            }else if(object.type == 'uploadlocal'){
                let findIndex = this.commonArrayFOr.findIndex((ele)=>{return (ele.lastModified + '_' +ele.name) == object.name})
                let findIndex2 = this.attachemntArray.findIndex((ele)=>{return ele.name == object.name})
                if(findIndex != -1){
                    this.commonArrayFOr.splice(findIndex,1)
                }
                if(findIndex2 != -1){
                    this.attachemntArray.splice(findIndex2,1)
                }
                this.$toast.success("PDF deleted successfully.")
            }
             
            // this.removeDocIndex = '';
        },
        cancelStatusDoc(){
            this.removeDocIndex = '';
        },
        removeVideo(){
            console.log('this.videoUrl.includesfirebase',this.videoUrl.includes('firebase'))
            // if(this.videoUrl.includes('firebase')){

            // }
            if(this.videoUrl.includes('firebase')){
                let httpsReference = firebase.storage().refFromURL(this.videoUrl)
                let name = httpsReference.name
                console.log(name)
                // removeImage("realEstate/", name, (res) => {
                //     console.log('DONE',res)
                //     if(res){
                    this.removeItemList.push(name);
                        this.videoUrl = ''
                        this.$toast.success("Video deleted successfully.")
                    // }else{
                    //     console.log('ERROR')
                    // }
                // });
            }else{
                let findIndex = this.commonArrayFOr.findIndex((ele)=>{return ele.type.includes('video')})
                console.log(findIndex,'findIndex')
                if(findIndex != -1){
                    this.commonArrayFOr.splice(findIndex,1)
                }
                this.videoUrl = ''
                this.$toast.success("Video deleted successfully.")
            }
            // this.videoUrl = ''
            // this.$toast.success("Video deleted successfully.")
            // this.$bvModal.hide(`modal-removeDoc`);
        },
        cancelStatusVideo(){
            this.$bvModal.hide(`modal-removeVideo`);
        },
        goBackFun(){
            this.$router.go(-1);
        },
        latAndLong(){
            this.formData = {...this.formData, leaseBLongitude: '', leaseBLatitude:''}
        },
        changeDateFormat(date, format) {
            if(date.seconds) {
                return moment(new Date(date.seconds * 1000)).format(format);
            } else {
                return moment(new Date(date)).format(format);
            }
        },
        // changeSelect() {
        //     var self = this;
        //     try {
        //         let val = self.formData.leaseBCountry;
        //         self.stateList = [];
        //         self.stateList = State.getStatesOfCountry(val.isoCode);
        //         if(self.stateList.length !== 0){
        //             this.formData.leaseBState = self.stateList[0].name;
        //         }
        //         this.displayErrorMessage = false
        //     } catch (error) {
        //         console.log(error);
        //     }
        // },
        documentName(doc){
            var httpsReference = firebase.storage().refFromURL(doc)
            let name = httpsReference.name
            let arr = name.split('');
            arr.splice(0,1);
            return arr.join("");
        },
        handleChangeRehab(e){
            var self = this;
            const { value, checked } = e.target
            console.log("valueeeeeerrrrrr",value)
            if (checked) {
                self.Rehab.push(value);
            } else {
                const index = self.Rehab.findIndex(id => id === value)
            if (index > -1) {
                self.Rehab.splice(index, 1)
            }
            }
            self.formData.leaseCRehabType = self.Rehab;
            console.log(self.Rehab)
        },
        listVideoUploads(e) {
            var self = this;
            try {
                let files = e?.srcElement?.files ? e.srcElement.files : e;
                let mb = 5;
                let validFileSize = false;
                let formatArray = ['video/x-matroska','video/webm','video/quicktime','video/mp4']
                // if(this.videoUrl !== ''){
                //     this.$toast.error("Only one video file allowed.");
                // }
                if(files && files.length !== 0 && this.videoUrl == '') {
                    self.filesArray = [];
                    self.filesArray = files;
                    self.filesArray.forEach((element)=>{
                        if(element.size >= mb * 1000000 || !formatArray.includes(element.type)){
                        validFileSize = true;
                        return;
                        }
                    });
                    if(!validFileSize) {
                        try {
                            var fileObject = self.filesArray[0];
                            this.commonArrayFOr.push(fileObject);
                            this.videoUrl = URL.createObjectURL(fileObject);
                            this.$forceUpdate();
                            document.getElementById("uploadVideo").value=null;
                        } catch (error) {
                            console.log('ERROR',error)
                        }
                    }else{
                        // this.$toast.error("Only video file allowed and should be less than 5 mb.")
                        this.$toast.error("Please only upload video files in mp4,mov,webm,mkv,m4v format and less than 5 mb.")
                        document.getElementById("uploadVideo").value=null;
                        console.log('FILE SIZE IS MORE');
                    }
                }else{
                    this.$toast.error("Only one video file allowed.")
                    document.getElementById("uploadVideo").value=null;
                    console.log('you choose more than one video')
                }
            } catch (error) {
                console.error("ERRROR",error)
                document.getElementById("uploadVideo").value=null;
            }
        },
        listFileUploads(e) {
            var self = this;
            try {
                let files = e?.srcElement?.files ? e.srcElement.files : e;
                let mb = 5;
                let validFileSize = false;
                if(this.attachemntArray && this.attachemntArray.length >= 2){
                    this.$toast.error("Only 2 pdf file allowed.");
                    document.getElementById("uploadFileArray").value=null;
                    return;
                }
                console.log(this.attachemntArray.length,'this.attachemntArray.length')
                if((this.attachemntArray.length + files.length) > 2){
                    let value = (2 - this.attachemntArray.length)
                    let loopStop = false;
                    console.log(value,'value')
                    let newFileArray = []
                    files.forEach((ele,index)=>{
                        console.log(value,'value',index)
                        if(index <= (value-1)){
                            newFileArray.push(ele)
                            loopStop = true;
                        }
                    })   
                    if(loopStop){
                        files = newFileArray
                    }
                }
                if(files && files.length !== 0 && files.length <= 2) {
                    self.filesArray = [];
                    self.filesArray = files;
                    self.filesArray.forEach((element)=>{
                        if(element.size >= mb * 1000000 || element.type !== 'application/pdf'){
                        validFileSize = true;
                        return;
                        }
                    });
                    if(!validFileSize) {
                        let attachmentsURLsArray = [];
                        let count = 0;
                        try {
                            const uploadFun = async (row) => {
                                if(count >= self.filesArray.length) {
                                    if(self.attachemntArray == undefined || self.attachemntArray.length == 0) {
                                        const obj = {
                                            'attachments': attachmentsURLsArray
                                        }
                                        if(Object.keys(obj).length > 0 && obj !== null){
                                            this.attachemntArray = attachmentsURLsArray
                                            this.$forceUpdate();
                                        }
                                    } else {
                                        const fileArrays = self.attachemntArray;
                                        attachmentsURLsArray.forEach(element => {
                                            fileArrays.push(element);
                                        });
                                        const obj = {
                                            'attachments': fileArrays
                                        }
                                        if(Object.keys(obj).length > 0 && obj !== null){
                                            this.attachemntArray = fileArrays
                                            this.$forceUpdate();
                                        }
                                    }
                                    document.getElementById("uploadFileArray").value=null;
                                    return;
                                }
                                this.commonArrayFOr.push(row);
                                var fileObject = row;
                                var url = fileObject.name;
                                    const imgObj = {
                                        'fileName':url,
                                        'type':'uploadlocal',
                                        'name':fileObject.lastModified + '_' + fileObject.name
                                    }
                                    attachmentsURLsArray.push(imgObj);
                                count += 1;
                                uploadFun(self.filesArray[count]);
                            }
                            uploadFun(self.filesArray[count]);
                        } catch (error) {
                            console.log('ERROR',error)
                        }
                    }else{
                        this.$toast.error("Only pdf file allowed and should be less than 5 mb.")
                        document.getElementById("uploadFileArray").value=null;
                        console.log('FILE SIZE IS MORE');
                    }
                }else{
                    this.$toast.error("Only 2 pdf file allowed.")
                    document.getElementById("uploadFileArray").value=null;
                    console.log('FILE SHOULD BE LESS THAN 2')
                }
            } catch (error) {
                console.error("ERRROR",error)
                document.getElementById("uploadFileArray").value=null;
            }
        },
        listImageUploads(e) {
            var self = this;
            try {
                let files = e?.srcElement?.files ? e.srcElement.files : e;
                let mb = 2;
                let validFileSize = false;
                console.log(files,'files')
                if(this.imagesArray && this.imagesArray.length >= 20){
                    this.$toast.error("Only 20 image file allowed.");
                    document.getElementById("UploadedFile").value=null;
                    return;
                }
                console.log(this.imagesArray.length,'this.imagesArray.length')
                if((this.imagesArray.length + files.length) > 20){
                    let value = (20 - this.imagesArray.length)
                    let loopStop = false;
                    console.log(value,'value')
                    let newFileArray = []
                    files.forEach((ele,index)=>{
                        console.log(value,'value',index)
                        if(index <= (value-1)){
                            newFileArray.push(ele)
                            loopStop = true;
                        }
                    })   
                    if(loopStop){
                        files = newFileArray
                    }
                }
                if(files && files.length !== 0 && files.length <= 20) {
                    self.filesArray = [];
                    self.filesArray = files;
                    self.filesArray.forEach((element)=>{
                        if(element.size >= mb * 1000000 || !element.type.includes("image")){
                        validFileSize = true;
                        return;
                        }
                    });
                    if(!validFileSize) {
                        let attachmentsURLsArray = [];
                        let count = 0;
                        try {
                            const uploadFun = async (row) => {
                            if(count >= self.filesArray.length) {
                                    if(this.imagesArray  == undefined || this.imagesArray.length == 0) {
                                        const obj = {
                                            'attachments': attachmentsURLsArray
                                        }
                                        if(Object.keys(obj).length > 0 && obj !== null){
                                            //INSERT QUERY WHEN FIRST TIME
                                            this.imagesArray  = attachmentsURLsArray;
                                            this.$forceUpdate()
                                        }
                                    } else {
                                        const fileArrays = this.imagesArray;
                                        attachmentsURLsArray.forEach(element => {
                                            fileArrays.unshift(element);
                                        });
                                        this.imagesArray  = fileArrays
                                        this.$forceUpdate()
                                        //INSERT QUERY WHEN second TIME
                                    }
                                    document.getElementById("UploadedFile").value=null;
                                    return;
                                }
                                this.commonArrayFOr.push(row);
                                // this.formData.leaseFUploadPhotos.push(row);
                                var fileObject = row;
                                var url = fileObject.name;
                                var myRnId = parseInt(Date.now() * Math.random());
                                var fileName = myRnId + "_" + url;
                                    const imgObj = {
                                        'imgURL':fileName,
                                        'fileUrl':URL.createObjectURL(fileObject),
                                        'type':'uploadlocal',
                                        'name':fileObject.lastModified + '_' + fileObject.name
                                    }
                                    attachmentsURLsArray.push(imgObj);
                                count += 1;
                                uploadFun(self.filesArray[count]);
                            }
                            uploadFun(self.filesArray[count]);
                        } catch (error) {
                            console.log('ERROR',error)
                        }
                    }else{
                        this.$toast.error("Only image file allowed and should be less than 2 mb.")
                        document.getElementById("UploadedFile").value=null;
                        console.log('FILE SIZE IS MORE');
                    }
                }else{
                    this.$toast.error("Only 20 image file allowed.")
                    document.getElementById("UploadedFile").value=null;
                    console.log('IMAGE ONLY 20 ALOWED')
                }
            } catch (error) {
                console.error("ERRROR",error)
                document.getElementById("UploadedFile").value=null;
            }
        },
        // companyName(){
        //     this.formData.leaseAMortgageNoteOwnedByCompanyStateWhereCompanyFormedIn = ''
        // },
        companyNameValue(){
            this.formData.leaseAMortgageNoteOwnedByCompanyOperatorsOfTheCompany = ''
        },
        companyValue(){
            this.formData.leaseCAnnualHOAAmount = ''
        },
        uploadImageInFirebase(){
            console.log('FUN CAALEEE')
            var self = this;
            let attachmentsURLsArray = [];
            self.formData.leaseFUploadVideo = self.videoUrl ? self.videoUrl : ''
            self.formData.leaseFUploadSeveralPages = []
            self.formData.leaseFUploadPhotos =  []
            if(self.localArray.length !== 0){
                self.localArray.forEach((ele)=>{
                    self.formData.leaseFUploadPhotos.push(ele)
                })
            }
            if(self.localFileArray.length !== 0){
                self.localFileArray.forEach((ele)=>{
                    self.formData.leaseFUploadSeveralPages.push(ele)
                })
            }
            console.log(self.formData.leaseFUploadPhotos,self.formData.leaseFUploadSeveralPages,self.formData.leaseFUploadVideo)
            console.log(self.attachemntArray,self.imagesArray)
            let count = 0;
            try {
                const uploadFun = async (row) => {
                    console.log('ROW')
                    if(count >= self.commonArrayFOr.length) {
                        try{
                            let entry = false;
                            if(self.commonArrayFOr.length == 0){
                                entry = true;
                            }
                            attachmentsURLsArray.forEach((ele,index)=>{
                                if(ele.type.includes('image')){
                                    self.formData.leaseFUploadPhotos.push(ele.imgURL)
                                }
                                if(ele.type === 'application/pdf'){
                                    self.formData.leaseFUploadSeveralPages.push(ele.imgURL)
                                }
                                if(ele.type.includes('video')){
                                    self.formData.leaseFUploadVideo = ele.imgURL
                                }
                                if((index+1) === attachmentsURLsArray.length){
                                    entry = true;
                                }
                            })
                            console.log('ROW----------2')
                            if(entry){
                                console.log('ROW------------3')
                                console.log(self.formData,'self.formData----STEP 1')
                                if(typeof self.formData.leaseDOriginationDate === 'string'){
                                    self.formData = {
                                        ...self.formData,
                                        leaseDOriginationDate:new Date(self.formData.leaseDOriginationDate),
                                        // leaseDMaturityDate:new Date(self.formData.leaseDMaturityDate),
                                        // leaseDLastPaymentDate:new Date(self.formData.leaseDLastPaymentDate),
                                    }

                                }
                                let address = {
                                    streetNumber: self.formData.leaseAStreetNumber,
                                    streetName: self.formData.leaseAStreetName,
                                    unitOrLot: self.formData.leaseAUnitorLot,
                                    city: self.formData.leaseBCity,
                                    state: self.formData.leaseBState,
                                    zipCode: self.formData.leaseBZipCode,
                                }
                                checkAddress(address, self.$route.params.id).then(async (resAdd) =>{
                                    if(self.formData.leaseCIsleaseAUnitorLot == true) {
                                        self.formData = {...self.formData,leaseAUnitorLot:''}
                                    }
                                    if(resAdd.isValid) {
                                        console.log("self.formData.leaseBLatitude",self.formData.leaseBLatitude)
                                        if(self.formData.leaseBLatitude === '' || self.formData.leaseBLongitude === ''){
                                            console.log("valueeeee")
                                            await self.$gmapApiPromiseLazy().then((res) =>{
                                            let geocoder = new res.maps.Geocoder();
                                                geocoder.geocode(
                                                    { 'address': `${self.formData.leaseAStreetNumber},${self.formData.leaseAStreetName},${self.formData.leaseBCity},${self.formData.leaseBState},${self.formData.leaseBCountry}`},
                                                    async function(results, status) {
                                                        if (status == res.maps.GeocoderStatus.OK) {
                                                            var latitude = results[0].geometry.location.lat();
                                                            var longitude = results[0].geometry.location.lng();
                                                            console.log(latitude, longitude);
                                                            self.formData = {...self.formData,leaseBLatitude:latitude.toString(),leaseBLongitude:longitude.toString()}
                                                            console.log('ROW-------------4',self.$route.query,self.formData)   
                                                            await updateFirebase.updateRootCollectionDataByDocId(
                                                                dbCollections.PROPERTY,
                                                                self.$route.params.id,
                                                                self.formData,
                                                                (res) => {
                                                                    console.log('UPDATE CALLED',res);
                                                                    let typesenseUpdateObj = {
                                                                        ...self.formData,
                                                                        status: parseInt(self.formData.status),
                                                                        isFeatured: self.formData.isFeatured !== undefined ? self.formData.isFeatured  : false, 
                                                                        createdAt: self.formData.createdAt ? (self.formData.createdAt.seconds ? self.formData.createdAt.seconds : Math.floor(new Date(self.formData.createdAt).getTime() / 1000)) : Math.floor(new Date().getTime()/1000),
                                                                        updatedAt: self.formData.updatedAt ? (self.formData.updatedAt.seconds ? self.formData.updatedAt.seconds : Math.floor(new Date(self.formData.updatedAt).getTime() / 1000)) : Math.floor(new Date().getTime()/1000),
                                                                        beds : parseFloat(self.formData.leaseBBedroomCount),
                                                                        baths : parseFloat(self.formData.leaseBBathroomCount),
                                                                        imageUrl :  self.formData.leaseFUploadPhotos ? self.formData.leaseFUploadPhotos : [] ,
                                                                        streetNumber : `${self.formData.leaseAStreetNumber}`,
                                                                        streetName : self.formData.leaseAStreetName,
                                                                        unitOrLot: self.formData.leaseAUnitorLot,
                                                                        city : self.formData.leaseBCity,
                                                                        state : self.formData.leaseBState,
                                                                        country : self.formData.leaseBCountry,
                                                                        zipCode : `${self.formData.leaseBZipCode}`,
                                                                        address : `${self.formData.leaseAStreetNumber} ${self.formData.leaseAStreetName}, ${self.formData.leaseBCity}, ${self.formData.leaseBState}, ${self.formData.leaseBCountry} ${self.formData.leaseBZipCode}`,
                                                                        longitude : self.formData.leaseBLongitude ? parseFloat(self.formData.leaseBLongitude) : 0,
                                                                        latittude : self.formData.leaseBLatitude ? parseFloat(self.formData.leaseBLatitude) : 0,
                                                                        // price : parseFloat(self.formData.leaseDAskingPrice),
                                                                        vacancies: String(self.formData.leaseDNumberOfVacancies),
                                                                        gender : self.formData.leaseBGender,
                                                                        age : self.formData.leaseBAge,
                                                                        payment : self.formData.leaseBPaymentMethod,
                                                                        los : self.formData.leaseBLengthOfStay,
                                                                    }
                                                                    if(self.formData.subscriptionDate) {
                                                                        typesenseUpdateObj.subscriptionDate = self.formData.subscriptionDate.seconds ? self.formData.subscriptionDate.seconds : new Date(self.formData.subscriptionDate).getTime() / 1000;
                                                                    }
                                                                    if(self.formData.subscriptionStatus){
                                                                        typesenseUpdateObj.subscriptionStatus = parseInt(self.formData.subscriptionStatus)
                                                                    }
                                                                    if(self.formData.offersCount) {
                                                                        typesenseUpdateObj.offersCount = parseInt(self.formData.offersCount)
                                                                    }
                                                                    if(self.formData.nextRenewalDate) {
                                                                        typesenseUpdateObj.nextRenewalDate = self.formData.nextRenewalDate.seconds ? self.formData.nextRenewalDate.seconds : new Date(self.formData.nextRenewalDate).getTime() / 1000;
                                                                    }
                                                                    if(self.formData.autoRenewalDate) {
                                                                        typesenseUpdateObj.autoRenewalDate = self.formData.autoRenewalDate.seconds ? self.formData.autoRenewalDate.seconds : new Date(self.formData.autoRenewalDate).getTime() / 1000;
                                                                    }
                                                                    updateTypesense.updatePropertyCollection(self.$route.params.id, typesenseUpdateObj).then(() => {
                                                                        console.log("UPDATED IN TYPESENSE");
                                                                    })
                                                                    .catch((error) =>{
                                                                        console.error(error);
                                                                    })
                                                                    self.$forceUpdate();
                                                                    self.isSpinner = false;
                                                                    self.localArray = [];
                                                                    self.localFileArray = [];
                                                                    self.$toast.success("Property updated succesfully");
                                                                    if (self.$route.query.name == 'dashboard') {
                                                                        self.$router.push({name: 'dashboard', query:{name:'dashboard'}});
                                                                    }
                                                                    if (self.$route.query.name =='myList') {
                                                                        self.$router.push({name: 'listing-management', query:{name:'myList'}});
                                                                    }
                                                                   
                                                                }
                                                            );
                                                        } 
                                                    }
                                                );
                                            })
                                        }
                                        else {
                                            console.log('elseeeeeee',self.formData.leaseDOriginationDate)   
                                            await updateFirebase.updateRootCollectionDataByDocId(
                                                dbCollections.PROPERTY,
                                                self.$route.params.id,
                                                self.formData,
                                                (res) => {
                                                    console.log('UPDATE CALLED',res);
                                                    let typesenseUpdateObj = {
                                                        ...self.formData,
                                                        status: parseInt(self.formData.status),
                                                        isFeatured: self.formData.isFeatured !== undefined ? self.formData.isFeatured  : false, 
                                                        createdAt: self.formData.createdAt ? (self.formData.createdAt.seconds ? self.formData.createdAt.seconds : Math.floor(new Date(self.formData.createdAt).getTime() / 1000)) : Math.floor(new Date().getTime()/1000),
                                                        updatedAt: self.formData.updatedAt ? (self.formData.updatedAt.seconds ? self.formData.updatedAt.seconds : Math.floor(new Date(self.formData.updatedAt).getTime() / 1000)) : Math.floor(new Date().getTime()/1000),
                                                        beds : parseFloat(self.formData.leaseBBedroomCount),
                                                        baths : parseFloat(self.formData.leaseBBathroomCount),
                                                        imageUrl :  self.formData.leaseFUploadPhotos ? self.formData.leaseFUploadPhotos : [] ,
                                                        streetNumber : `${self.formData.leaseAStreetNumber}`,
                                                        streetName : self.formData.leaseAStreetName,
                                                        unitOrLot: self.formData.leaseAUnitorLot,
                                                        city : self.formData.leaseBCity,
                                                        state : self.formData.leaseBState,
                                                        country : self.formData.leaseBCountry,
                                                        zipCode : `${self.formData.leaseBZipCode}`,
                                                        address : `${self.formData.leaseAStreetNumber} ${self.formData.leaseAStreetName}, ${self.formData.leaseBCity}, ${self.formData.leaseBState}, ${self.formData.leaseBCountry} ${self.formData.leaseBZipCode}`,
                                                        longitude : self.formData.leaseBLongitude ? parseFloat(self.formData.leaseBLongitude) : 0,
                                                        latittude : self.formData.leaseBLatitude ? parseFloat(self.formData.leaseBLatitude) : 0,
                                                        // price : parseFloat(self.formData.leaseDAskingPrice),
                                                        vacancies: String(self.formData.leaseDNumberOfVacancies),
                                                        gender : self.formData.leaseBGender,
                                                        age : self.formData.leaseBAge,
                                                        payment : self.formData.leaseBPaymentMethod,
                                                        los : self.formData.leaseBLengthOfStay,
                                                    }
                                                    if(self.formData.subscriptionDate) {
                                                        typesenseUpdateObj.subscriptionDate = self.formData.subscriptionDate.seconds ? self.formData.subscriptionDate.seconds : new Date(self.formData.subscriptionDate).getTime() / 1000;
                                                    }
                                                    if(self.formData.subscriptionStatus){
                                                        typesenseUpdateObj.subscriptionStatus = parseInt(self.formData.subscriptionStatus)
                                                    }
                                                    if(self.formData.offersCount) {
                                                        typesenseUpdateObj.offersCount = parseInt(self.formData.offersCount)
                                                    }
                                                    if(self.formData.nextRenewalDate) {
                                                        typesenseUpdateObj.nextRenewalDate = self.formData.nextRenewalDate.seconds ? self.formData.nextRenewalDate.seconds : new Date(self.formData.nextRenewalDate).getTime() / 1000;
                                                    }
                                                    if(self.formData.autoRenewalDate) {
                                                        typesenseUpdateObj.autoRenewalDate = self.formData.autoRenewalDate.seconds ? self.formData.autoRenewalDate.seconds : new Date(self.formData.autoRenewalDate).getTime() / 1000;
                                                    }
                                                    updateTypesense.updatePropertyCollection(self.$route.params.id, typesenseUpdateObj).then(() => {
                                                        console.log("UPDATED IN TYPESENSE");
                                                    })
                                                    .catch((error) =>{
                                                        console.error(error);
                                                    })
                                                    self.$forceUpdate();
                                                    self.isSpinner = false;
                                                    self.localArray = [];
                                                    self.localFileArray = [];
                                                    self.$toast.success("Property updated succesfully");
                                                    if (self.$route.query.name == 'dashboard') {
                                                        self.$router.push({name: 'dashboard', query:{name:'dashboard'}});
                                                    }
                                                    if (self.$route.query.name =='myList') {
                                                        self.$router.push({name: 'listing-management', query:{name:'myList'}});
                                                    }
                                                }
                                            );
                                        }
                                    } else {
                                        self.$bvModal.show('modal-alredyAddress');
                                        self.isSpinner = false;
                                    }
                                })
                                .catch((error) => {
                                    console.error(error);
                                    self.isSpinner = false;
                                })
                                if(self.getAllOfferArray && self.getAllOfferArray.length > 0){
                                    let index =  self.getAllOfferArray.findIndex(item => {
                                        return item.propertyId == self.$route.params.id
                                    })
                                    if(index != -1){
                                        console.log(self.getAllOfferArray[index],'self.getAllOfferArray[index');
                                        let propertyAddresss = `${self.formData.leaseAStreetNumber} ${self.formData.leaseAStreetName}, ${self.formData.leaseAUnitorLot}, ${self.formData.leaseBCity}, ${self.formData.leaseBState}, ${self.formData.leaseBCountry}, ${self.formData.leaseBZipCode}`
                                        let updateObject = {
                                            propertyAddress : propertyAddresss
                                        }
                                        updateFirebase.updateRootCollectionDataByDocId(dbCollections.OFFERS,self.getAllOfferArray[index].id,updateObject,(res) =>{
                                            console.log(res);
                                        })
                                    }
                                }   
                            }
                        }catch (error) {
                            console.error(error)
                            self.isSpinner = false;
                        }
                        // this.$toast.success("Your updates have been saved2222222222222.")
                        return;
                    }
                    var fileObject = row;
                    var url = fileObject.name;
                    var myRnId = parseInt(Date.now() * Math.random());
                    var fileName = myRnId + "_" + url;
                    await uploadImageAsPromise(fileObject, `realEstate/` , fileName).then((imgURL) => {
                        const imgObj = {
                            'imgURL':imgURL,
                            'type':fileObject.type
                        }
                        attachmentsURLsArray.push(imgObj);
                    });
                    console.log('ROW--------6')
                    count += 1;
                    uploadFun(self.commonArrayFOr[count]);
                }
                uploadFun(self.commonArrayFOr[count]);
            } catch (error) {
                console.log('ERROR',error)
                self.isSpinner = false;
            }
        },
        async updateButtonCall(){
            this.$refs.observer.validate().then(async(valid)=>{
            console.log('VALUD-----------',valid)
            if(valid){
                if(this.localArray.length === 0 && this.imagesArray.length === 0){
                    window.scrollTo(0, 0);
                    this.$toast.error("At least one image file required.")
                    return
                }
                window.scrollTo(0, 0);
                this.isSpinner = true
                await this.attachmentRemove();
                // await this.uploadImageInFirebase()
                console.log('DATATATATATATATATATATA',this.formData)
            }else{
                this.handleError();
            }
            })
        },
        OnlyNumber ($event) {
            let val = $event.target.value.replace(/[^0-9/.]/g,'');      
            if (val.charAt(0) == '.'){
                $event.target.value = "";
                $event.preventDefault();
            }
            if (($event.which != 46 || val.indexOf('.') != -1) && ($event.which < 48 || $event.which > 57)) {
                $event.preventDefault();
            }
        },
        imageModel(){
                this.$bvModal.show('modal-images')
        },
        attachmentRemove(){
            let count = 0;
            try {
                const uploadFun = async (row) => {
                    console.log('ROW')
                    if(count >= this.removeItemList.length) {
                        this.uploadImageInFirebase()
                        return;
                    }
                    removeImage("realEstate/", row, (res) => {
                        console.log('DONE',res)
                        if(res){
                            console.log("DONE REMOVE SUC");
                            count++
                            uploadFun(this.removeItemList[count]);
                        }else{
                            count++
                            uploadFun(this.removeItemList[count]);
                        }
                    });
                }
                uploadFun(this.removeItemList[count]);
            } catch (error) {
                console.log('ERROR',error)
            }
        },
    },
    computed:{
        ...mapGetters({
            getAllOfferArray:"offer/getAllOffer",
        })
    }
  };
  </script>
<style>
/* div#modal-images .modal-body div { */
    /* width: 33.33%; */
    /* float: left; */
    /* padding: 10px; */
/* } */

.images-wrapper div {
    width: 47%;
    height: 125px;
    border: 1px solid #ececec;
    margin: 6px;
    border-radius: 8px;
    background-color: #f6f6f6;
}

.images-wrapper div img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: scale-down;
}

.images-wrapper {
    display: flex;
    flex-wrap: wrap;
}
div#modal-images .modal-footer button.btn.btn-primary {
    display: none;
}

div#modal-images .modal-footer {
    justify-content: center;
}
.dateChanges {
    position: relative;
    background-color: white;
    width: 100%;
    height: 63px;
    border-radius: 10px;
    background-image: url('../../../../assets/images/svg/calendar.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 20px);
}
.dateValue {
    padding: 19px 20px;
    font-family: "Muller";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #848280;
}
input.InputFormClass.dateValuechanges::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}
input.InputFormClass.dateValuechanges {
    width: 100%;
}
input.InputFormClass.dateValuechanges[type=date] {
    padding: 0px;
    height: 63px;
    color: transparent;
    background-color:transparent;
    border-radius: 10px;
    width: 100%;
    line-height: 0px;
}
input.InputFormClass.dateValuechanges {
    background-color: #FFF;
    width: 200px;
    border-radius: 4px;
    padding: 0;
    position: absolute;
    left: 0px;
    padding-left: 1px;
    top: 0px;
}
.spinner {
  z-index: 999;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
.selectWrapper {
    padding-bottom: 15px;
}
.myListingsropertyDetail .radio-intake-merhod {
    width: 100% !important;
}
.myListingsropertyDetail .radio-housing-type {
    padding-right: 0 !important;
    width: 100%;
    margin-bottom:10px;
}
.multiselect__tags {
    border: 0 !important;
}
.multiselect__tags {
    padding: 12px 40px 0 8px !important;
}
@media(max-width:991px){
  input.InputFormClass.dateValuechanges[type=date] {
    padding: 0px !important;
    height: 45px !important;
  }
  .dateValue{
    height: 45px !important;
    padding: 8px 15px;
  }
  .dateChanges {
    height: 45px;
  }
}
@media(max-width:767px){
  /* .dateChanges{
    height: auto;
  } */
  .dateValue{
    font-size: 14px;
    padding: 12px 10px !important;
  }
}
</style>